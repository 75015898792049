<template>
  <a-alert
    style="margin-top: 0.5rem; margin-bottom: 0.5rem"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </a-alert>
</template>
<script>
import { Alert } from "ant-design-vue";

export default {
  name: "BaseAlert",
  inheritAttrs: false, // optional
  components: {
    "a-alert": Alert,
  },
};
</script>
