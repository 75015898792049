u
<template>
  <div
    :style="[
      bg && {
        backgroundColor: theme.color[bg] ? theme.color[bg] : bg,
      },
    ]"
    class="shadow card"
  >
    <div v-if="!!$slots['card-header']" class="card-header">
      <slot name="card-header"></slot>
    </div>
    <div v-if="!!$slots['card-body']" class="card-body">
      <slot name="card-body"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import theme from "@/components/theme";

export default {
  data() {
    return {
      theme,
    };
  },
  props: {
    /**
     * Bg
     */
    bg: {
      type: String,
      default: "white",
    },
  },
  name: "BaseCard",
  inheritAttrs: false,
};
</script>
<style lang="scss" scoped>
@import url("./base-styles/card.scss");
.base-table-main {
  border: 1px solid #2c3a4725;
}
</style>
