<template>
  <span class="base-avatar-wrapper">
    <a-avatar v-if="!src">
      <a-icon v-if="icon" :type="icon" />
      {{ imageText }}
    </a-avatar>
    <a-avatar v-else :src="src" />
  </span>
</template>

<script>
export default {
  name: "BaseAvatar",
  props: {
    icon: String,
    shape: String,
    size: String,
    src: String,
    srcSet: String,
    alt: String,
    loadError: Boolean,
    imageText: String,
    color: String,
    backgroundColor: String,
  },
  computed: {
    bgColor() {
      return this.props.backgroundColor;
    },
  },
};
</script>
