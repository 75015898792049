import auth0 from "@/services/auth0";
import currencyCodesJSON from "./gcp/currencyCodes.json";
import store from "@/store";
import router from "@/router";
import moment from "moment";
import yaml from "js-yaml";
import jwt_decode from "jwt-decode";
import * as firebase from "@/services/firebase";

function mergeArrayObjects(arr1, arr2, key) {
  return arr1.map((item, i) => {
    if (!arr2[i]) {
      return item;
    }
    if (item[key] === arr2[i][key]) {
      //merging two objects
      return Object.assign({}, item, arr2[i]);
    }
  });
}

const getDateRangeForWeekNumber = (weekNumber) => {
  // 20.12.2021 - 26.12.2021 - week 51
  let beginningOfWeek = moment()
    .add(weekNumber, "weeks")
    .day("monday")
    .add(1, "day");
  let endOfWeek = moment().add(weekNumber, "weeks").day("monday").add(7, "day");
  return `${beginningOfWeek.format("MMM DD")} - ${endOfWeek.format("MMM DD")}`;
};

function getWeekRange(week, year) {
  // TODO: Migrate from Moment to Luxon
  const startDate = moment().year(year).isoWeek(week).startOf("isoWeek");
  const endDate = moment().year(year).isoWeek(week).endOf("isoWeek");
  return `${startDate.date()} ${
    utils.monthNamesShort[startDate.month()]
  } - ${endDate.date()} ${utils.monthNamesShort[endDate.month()]}`;
}

const utils = {
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  weekNames: Array.from({ length: 54 }, (_, i) => "Week " + i).slice(1),
  dayNames: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
};

const barChartColors = {
  backgroundColor: [
    "rgba(255, 125, 105, 0.6)",
    "rgba(255, 239, 99, 0.6)",
    "rgba(255, 205, 86, 0.6)",
    "rgba(75, 192, 192, 0.6)",
    "rgba(54, 162, 235, 0.6)",
    "rgba(153, 102, 255, 0.6)",
    "rgba(201, 203, 207, 0.6)",
  ],
  borderColor: [
    "rgb(255, 99, 132)",
    "rgb(255, 159, 64)",
    "rgb(255, 205, 86)",
    "rgb(75, 192, 192)",
    "rgb(54, 162, 235)",
    "rgb(153, 102, 255)",
    "rgb(201, 203, 207)",
  ],
};

const chartColors = [
  ["jigglypuff", "rgb(255, 159, 243)"],
  ["casandara yellow", "rgb(254, 202, 87)"],
  ["pastel red", "rgb(255, 107, 107)"],
  ["megaman", "rgb(72, 219, 251)"],
  ["dark slate gray", "rgb(47, 79, 79)"],
  ["double dragon skin", "rgb(255, 159, 67)"],
  ["light green", "rgb(144, 238, 14)"],
  ["indigo", "rgb(75, 0, 130)"],
  ["saddle brown", "rgb(139, 69, 19)"],
  ["dark green", "rgb(0, 100, 0)"],
  ["orange red", "rgb(255, 69, 0)"],
  ["light blue ballerina", "rgb(200, 214, 229)"],
  ["imperial primer", "rgb(34, 47, 62)"],
  ["bleu de france", "rgb(46, 134, 222)"],
  ["blue bell", "rgb(52, 31, 151)"],
  ["storm petrel", "rgb(131, 149, 167)"],
  ["carrot orange", "rgb(229, 142, 38)"],
  ["dark sapphire", "rgb(12, 36, 97)"],
  ["dupain", "rgb(96, 163, 188)"],
  ["livid", "rgb(106, 137, 204)"],
  ["flat flesh", "rgb(250, 211, 144)"],
  ["jalapeno red", "rgb(183, 21, 64)"],
];

const lightChartColors = [
  "rgb(27, 175, 252)", // blue-500
  "rgb(161, 222, 254)", // blue-400
  "rgb(40, 79, 147)", // blue-700
  "rgb(39, 127, 254)", // blue-600
  "rgb(107, 97, 243)", // blue-300
  "rgb(65, 54, 219)", // purple-700
  "rgb(53, 44, 186)", // purple-600
  "rgb(88, 77, 236)", // purple-500
  "rgb(107, 97, 243)", // purple-400
  "rgb(136, 120, 252)", // purple-300
];

const urls = ["/public/validate/token"];

import axios from "axios";

async function axiosHeaders() {
  let token = auth0.accessToken;
  if (token === null || token === "") {
    await auth0.renewTokens();
    token = auth0.accessToken;
  }

  const decodedToken = jwt_decode(token);
  if (Date.now() >= decodedToken.exp * 1000) {
    await auth0.renewTokens();
    token = auth0.accessToken;
  }
  return { Authorization: `Bearer ${token}` };
}

async function getStrapiContent(route) {
  const response = await fetch(`https://strapi.economize.cloud/${route}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  // to sort the data by Id as from strapi
  data.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
  });
  return data;
}

axios.interceptors.request.use(async function (config) {
  let filteredUrl = config.url.split("/api")[1];
  let token = "";

  if (!urls.includes(filteredUrl)) {
    token = await axiosHeaders();
    config.headers.Authorization = token.Authorization;
  }

  if (store.state.user.recordingURL) {
    config.headers["X-LogRocket-URL"] = store.state.user.recordingURL;
  }

  const orgID = store.state.user.organization_id;
  config.params = {
    ...config.params,
    orgID: store.state.user.organization_id,
  };

  return config;
});

const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

const exportToCSVWithColumn = function (tableData, tableName, tableColumn) {
  const column = Object.keys(tableData[0])
    .map((arr) => tableColumn[arr] ?? arr)
    .join(",");
  let csvContent = "data:text/csv;charset=utf-8,";
  const table = [
    column,
    ...tableData.map((item) => {
      const data = objectMap(item, (v) =>
        typeof v === "string" ? v : Math.round(v * 1000) / 1000
      );
      return Object.values(data).join(",").replace(/,\s*$/u, "");
    }),
  ];
  csvContent += encodeURIComponent(table.join("\n"));
  const data = csvContent;
  const downloadLink = document.createElement("a");
  const fileName = tableName + ".csv";
  downloadLink.setAttribute("href", data);
  downloadLink.setAttribute("download", fileName);
  downloadLink.click();
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);

    if (error.code === "login_required") {
      localStorage.removeItem("isLoggedIn");
      router.push("/login");
    }

    if (error.response.status === 503) {
      store.commit("user/SET_STATE", {
        maintenanceMode: true,
      });
      router.push({ path: "/public/maintenance" });
    }
    return Promise.reject(error);
  }
);

const exportToCSV = function (tableData, tableName) {
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += [
    Object.keys(tableData[0]).join(","),
    ...tableData.map((item) =>
      Object.values(item).join(",").replace(/,\s*$/u, "")
    ),
  ].join("\n");
  const data = encodeURI(csvContent);
  const downloadLink = document.createElement("a");
  const fileName = tableName + ".csv";
  downloadLink.setAttribute("href", data);
  downloadLink.setAttribute("download", fileName);
  downloadLink.click();
};

const exportToYAML = function (permissions, projectData) {
  const permissionArray = permissions.split(/\s*,\s*/u);
  const yamlContent = "data:text/yaml;charset=utf-8,";
  const data = {
    title: projectData.title,
    description: projectData.description,
    stage: projectData.stage,
    includedPermissions: permissionArray,
  };
  const result = yaml.dump(data);
  const yamlFile = encodeURI(yamlContent + result);
  const downloadLink = document.createElement("a");
  const fileName = "economize-role.yaml";
  downloadLink.setAttribute("href", yamlFile);
  downloadLink.setAttribute("download", fileName);
  downloadLink.click();
};

const exportToSH = function (data) {
  const fileType = "data:text/plain;charset=utf-8,";
  const fileContent = `
  echo "Economize"
  custom_role_output=""
  service_account_update=""
  email_output=""
  add_service_account_update=""
  download_service_account_key=""

  custom_role_output=$(gcloud iam roles create ${data.role_name} --project=${data.project_id} --file ./economize-role.yaml --format="value(etag)")

  if [ -z "$custom_role_output" ]
  then
    echo "Error: Creating custom role failure"
    exit 1
  fi
  if [ -z service_account_update=$(gcloud iam service-accounts create ${data.service_account_id} --description=${data.service_account_id} --display-name=${data.service_account_id}) ]
  then
    echo "Error: Creating service account failure"
    exit 1
  fi
  sleep 3
  email_output=$(gcloud iam service-accounts list --filter "${data.service_account_id}" --format="value(EMAIL)")
  echo "$email_output"
  if [ -z "$email_output" ]
  then
    echo "Error: Getting email failure"
    exit 1
  fi
  add_service_account_update=$(gcloud projects add-iam-policy-binding ${data.project_id} --role=projects/${data.project_id}/roles/${data.role_name} --member serviceAccount:"$email_output")
  if [ -z "$add_service_account_update" ]
  then
    echo "Error: Add role to service account failed"
    exit 1
  fi
  if [ -z download_service_account_key=$(gcloud iam serviceAccountsaccounts keys create ./${data.service_account_id}-key.json --iam-account "$email_output") ]
  then
    echo "Error: Downloading service account key failed"
    exit 1
  fi
  echo "\nSuccess!"
  echo "\nYour email: $email_output"
  echo "\nService account key downloaded to file: ${data.service_account_id}-key.json"
  exit 0
  `;
  const shFile = encodeURI(fileType + fileContent);
  const downloadLink = document.createElement("a");
  const fileName = "economize-script.sh";
  downloadLink.setAttribute("href", shFile);
  downloadLink.setAttribute("download", fileName);
  downloadLink.click();
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

const currencyCodeSymbolMap = currencyCodesJSON.currencyCodes.reduce(
  (map, obj) => {
    map[obj.code] = obj.symbol;
    return map;
  },
  {}
);
// method to round 2 decimal places
const round2Places = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};
// method to separate numbers as comma separated values
const numberWithCommas = (x) => {
  return round2Places(parseFloat(x)).toLocaleString("en-US");
};

function getNumberUnit(labelValue) {
  const sign = Math.sign(Number(labelValue));
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? sign * (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? sign * (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? sign * (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue)) === 0.0e3
    ? sign * Math.abs(Number(labelValue))
    : sign * Math.abs(Number(labelValue)).toFixed(2);
}

function getStorageUnit(labelValue) {
  const sign = Math.sign(Number(labelValue));
  // 15 Zeroes for Petabyte
  return Math.abs(Number(labelValue)) >= 1.0e15
    ? sign * (Math.abs(Number(labelValue)) / 1.0e15).toFixed(2) + "PB"
    : // 12 Zeroes for Terabyte
    Math.abs(Number(labelValue)) >= 1.0e12
    ? sign * (Math.abs(Number(labelValue)) / 1.0e12).toFixed(2) + "TB"
    : // 9 Zeroes for Gigabyte
    Math.abs(Number(labelValue)) >= 1.0e9
    ? sign * (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "GB"
    : Math.abs(Number(labelValue)) >= 1.0e6
    ? sign * (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "MB"
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? sign * (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "KB"
    : sign * Math.abs(Number(labelValue)).toFixed(2) + "B";
}

const formatWithCurrency = function (
  cost,
  code = store.state.user.selectedProject.currency
) {
  if (!(code in currencyCodeSymbolMap)) {
    return numberWithCommas(cost).toString() + " " + code;
  }
  const symbol = currencyCodeSymbolMap[code];
  return symbol + " " + numberWithCommas(cost).toString();
};

const formatWithCurrencyUnit = function (
  cost,
  code = store.state.user.selectedProject.currency ?? "USD"
) {
  if (!(code in currencyCodeSymbolMap)) {
    return numberWithCommas(cost).toString() + " " + code;
  }
  const symbol = currencyCodeSymbolMap[code];
  return symbol + " " + getNumberUnit(cost);
};

const formatWithStorageUnit = function (value) {
  return getStorageUnit(value);
};

const formatPermissionError = function (permission) {
  return (
    "Provide permission `" +
    permission +
    "` to your service account to view this data"
  );
};

const generateMonths = (n = 6) => {
  // Generate last n months. If today was 2021-03 and n=6, generate [202010, 202011, ..., 202106, 202107]
  let months = [];
  let endDate = new Date(Date.now());
  if (endDate.getDate() <= 1) endDate.setMonth(endDate.getMonth() - 1);
  let currDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    1,
    0,
    0,
    0,
    0
  );
  currDate.setMonth(endDate.getMonth() - n);
  while (
    currDate.getFullYear() * 100 + currDate.getMonth() <=
    endDate.getFullYear() * 100 + endDate.getMonth()
  ) {
    months.push(
      currDate.getFullYear().toString() +
        (currDate.getMonth() + 1).toString().padStart(2, "0")
    );
    currDate.setMonth(currDate.getMonth() + 1);
  }
  return months.reverse();
};

const strSort = (attr) => (a, b) =>
  a[attr] < b[attr] ? -1 : a[attr] > b[attr] ? 1 : 0;
const unknownError = "Unknown error occurred";

const errorMessages = {
  SomethingWentWrong: "Something went wrong, please try again later.",
  PermissionDenied:
    "The account integrated does not have sufficient permissions, please ensure you have required permissions and try again.",
  NotAuthenticated: "We are unable to authenticate you right now, try again.",
  InvalidInput: "One of the inputs supplied is not valid, try searching again.",
  AlreadyExists:
    "Unable to finish operation, the item being created already exists.",
};

const errorMessageMap = {
  InternalServerError: errorMessages.SomethingWentWrong,
  ResourceNotFound: errorMessages.SomethingWentWrong,
  PermissionDenied: errorMessages.PermissionDenied,
  NoAuthenticationInformation: errorMessages.NotAuthenticated,
  AuthenticationFailed: errorMessages.NotAuthenticated,
  InvalidRequestData: errorMessages.InvalidInput,
  InvalidParameterValue: errorMessages.InvalidInput,
  DataNotFound: errorMessages.InvalidInput,
  ResourceAlreadyExists: errorMessages.AlreadyExists,
};

const apiUrl = {
  dev: `http://${window.location.hostname}:8443`,
  prod: "https://apis.economize.cloud/api",
  stg: "https://" + window.location.hostname + "/api",
};

export default {
  name: "common",
  chartColors: chartColors,
  barChartColors: barChartColors,
  utils: utils,
  axios: axios,
  apiURL: apiUrl[process.env.VUE_APP_MODE] ?? apiUrl.stg,
  exportToCSV: exportToCSV,
  isEmptyObject,
  currencyCodes: currencyCodesJSON.currencyCodes,
  currencyCodeSymbolMap,
  formatWithCurrency,
  formatWithCurrencyUnit,
  formatWithStorageUnit,
  numberWithCommas,
  formatPermissionError,
  unknownError,
  formatBytesToMB: (text) => (text / Math.pow(10, 6)).toFixed(2) + " MB",
  formatDayOfWeek: (dow) => utils.dayNames[dow],
  formatDateToTime: (date) => moment(new Date(date)).format("HH:mm"),
  formatDateToLocalTime: (date) => moment.utc(date).local().format(),
  formatDateToLocalTimeObject: (date) => moment.utc(date).local(),
  formatDateToTimeString: (date) =>
    moment(new Date(date)).format("DD/MM/YYYY HH:mm"),
  capitalizeFirstLetter: (word) => word.charAt(0).toUpperCase() + word.slice(1),
  exportToYAML: exportToYAML,
  exportToSH,
  generateMonths,
  lightChartColors,
  strSort,
  getDateRangeForWeekNumber,
  getWeekRange,
  objectMap,
  exportToCSVWithColumn,
  getStrapiContent,
  errorMessageMap: errorMessageMap,
  mergeArrayObjects,
};
