import { Auth0Lock } from "auth0-lock";
import EventEmitter from "events";
import store from "@/store";
import authConfigProd from "./config-prod.json";
import authConfigDev from "./config-dev.json";
import router from "../../router";
import axios from "axios";
import NProgress from "vue-nprogress";
import common from "@/views/dashboard/common";

const localStorageKey = "isLoggedIn";
const mode = process.env.VUE_APP_MODE;
const authConfig = mode === "prod" ? authConfigProd : authConfigDev;
const URL = authConfig.audience;
const Rest_Password_URL = authConfig.reset_password_url;
export { authConfig };

const nprogress = new NProgress({ parent: "body" });
const uninterceptedAxiosInstance = axios.create();

const defaultConfig = {
  container: "hiw-login-container",
  rememberLastLogin: false,
  theme: {
    logo: "https://ik.imagekit.io/economize/economize-black_j6jdJ0w3X.png?updatedAt=1623089224499",
    // primaryColor: "#00aafa",
    authButtons: {
      "google-oauth2": {
        foregroundColor: "white",
        // primaryColor: "#2563eb",
      },
    },
  },
  languageDictionary: {
    title: "Sign in",
    loginSubmitLabel: "Sign in",
    signUpTitle: "Start your risk-free trial",
    signUpSubmitLabel: "Create Account",
    emailInputPlaceholder: "Email",
    passwordInputPlaceholder: "Password",
    forgotPasswordAction: "Forgot your password?",
    error: {
      signUp: {
        invalid_signup: "Account already exists",
      },
    },
  },
  auth: {
    redirectUrl: window.location.origin + "/login",
    responseType: "token id_token",
    params: {
      scope: "openid profile user_metadata email",
    },
    audience: `${URL}/`,
    sso: true,
    autoParseHash: true,
  },
  additionalSignUpFields: [
    // {
    //   name: "name",
    //   placeholder: "Full Name",
    //   storage: "root",
    //   validator: function (name) {
    //     return {
    //       valid: name.length >= 3,
    //       hint: "Must have 3 or more chars",
    //     };
    //   },
    // },
    // {
    //   name: "organization",
    //   placeholder: "Organization",
    //   validator: function (organization) {
    //     return {
    //       valid: organization.length >= 3,
    //       hint: "Must have 3 or more chars",
    //     };
    //   },
    // },
    {
      name: "phone",
      placeholder: "Phone Number",
      validator: function (phone) {
        return {
          valid: phone.length >= 10 && phone.length < 15,
          hint: "Please enter a valid phone number",
        };
      },
    },
    // {
    //   type: "checkbox",
    //   name: "newsletter",
    //   prefill: "true",
    //   placeholder:
    //     "I'd like to get Cloud Cost Optimization tips and tricks to my inbox",
    // },
  ],
  mustAcceptTerms: false,
  hooks: {
    async loggingIn(context, cb) {
      // this is login
      const nprogress = new NProgress({ parent: "body" });
      nprogress.start();
      const email = document.querySelector("#\\31 -email");
      if (email && email.value) {
        const uninterceptedAxiosInstance = axios.create();
        await uninterceptedAxiosInstance
          .get(common.apiURL + `/public/users/migrated`, {
            params: {
              email: email.value,
            },
          })
          .then((res) => {
            const json = res.data;

            // this one
            if (!json.migrated && json.is_user_exist) {
              // to check migrate
              uninterceptedAxiosInstance
                .post(Rest_Password_URL, {
                  email: email.value,
                  connection: "Username-Password-Authentication",
                })
                .then((res) => {
                  if (res.status === 200) {
                    router.push("/auth/auth-upgrade");
                  }
                })
                .catch((err) => {});
            }
          })
          .catch((err) => {})
          .finally(() => {
            nprogress.done();
            cb();
          });
      } else {
        nprogress.done();
        cb();
      }
    },
  },
};

class AuthService extends EventEmitter {
  constructor() {
    super();
    this.newLock(null);
  }
  authListeners() {
    const vm = this;
    this.lock.on("authenticated", async function (authResult) {
      store.commit("user/SET_STATE", {
        loading: true,
      });
      // GOOGLE OAUTH2
      nprogress.start();

      if (authResult.idTokenPayload.sub.split("|")[0] === "google-oauth2") {
        await vm.GoogleLogin(authResult);
      } else {
        await vm.Normallogin(authResult);
      }

      store.commit("user/SET_STATE", {
        loading: false,
      });
    });

    this.lock.on("authorization_error", function (error) {
      localStorage.removeItem(localStorageKey);
      router.push("/login");
    });
  }
  newLock(prefill) {
    if (!prefill) {
      this.lock = new Auth0Lock(
        authConfig.clientId,
        authConfig.domain,
        defaultConfig
      );
    } else {
      let newConfig = defaultConfig;
      newConfig.auth.state = prefill.token;
      if (prefill.email) {
        newConfig["prefill"] = {
          email: prefill.email,
        };
      }
      newConfig.additionalSignUpFields[1]["prefill"] = prefill.organization;
      this.lock = new Auth0Lock(
        authConfig.clientId,
        authConfig.domain,
        newConfig
      );
    }
    this.idToken = null;
    this.accessToken = null;
    this.profile = null;
    this.tokenExpiry = null;
    this.authListeners();
  }
  async localLogin(authResult) {
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;

    if (!authResult.idTokenPayload[`${URL}/is_new`]) {
      localStorage.setItem(localStorageKey, "true");
    }

    await store.commit("user/SET_STATE", {
      name: this.profile.name,
      email: this.profile.email,
      emailVerified: this.profile.email_verified,
      avatar: this.profile.picture,
      providerId: this.profile.sub.substr(0, this.profile.sub.indexOf("|")),
      id: this.profile.sub,
      role: this.profile.role,
      has_multiple_auth_providers:
        this.profile[`${URL}/has_multiple_auth_providers`],
      authorized: true,
    });
  }
  renewTokens() {
    const vm = this;
    return new Promise((resolve, reject) => {
      if (localStorage.getItem(localStorageKey) !== "true") {
        router.push("/login");
        return reject();
      }

      return vm.lock.checkSession({}, async (err, authResult) => {
        if (err) {
          if (err.code === "login_required") {
            localStorage.removeItem("isLoggedIn");
            router.push("/login");
          }
          return reject(err);
        }
        await vm.localLogin(authResult);
        return resolve(authResult);
      });
    });
  }
  async GoogleLogin(authResult) {
    let joinOrg = false;
    const vm = this;

    // to get migrate user for boolean
    const migratedData = await uninterceptedAxiosInstance.get(
      common.apiURL + `/public/users/migrated`,
      {
        params: {
          email: authResult.idTokenPayload.email,
        },
      }
    );

    // to get migrate user for boolean
    const emailExist = await uninterceptedAxiosInstance.get(
      common.apiURL + `/public/users/email`,
      {
        params: {
          email: authResult.idTokenPayload.email,
        },
      }
    );

    const migrated = await migratedData.data;
    const email_exists = await emailExist.data;

    const payload = {
      orgName: null,
      email: null,
      token: null,
      hasMultipleAuthProviders:
        authResult.idTokenPayload[`${URL}/has_multiple_auth_providers`],
      hasPassword: !migrated.migrated && migrated.is_user_exist,
    };

    let state = authResult.state.split(" ");
    if (state.length === 2) {
      payload.token = state[1];
    }

    if (`${URL}/is_new` in authResult.idTokenPayload) {
      payload.orgName = authResult.idTokenPayload[`${URL}/organization`];
      payload.email = authResult.idTokenPayload.email;
    }

    await vm.localLogin(authResult);

    payload.is_user_exist = email_exists.is_user_exist;
    if (localStorage.getItem("token") && localStorage.getItem("orgName")) {
      payload.token = localStorage.getItem("token");
      payload.orgName = localStorage.getItem("orgName");

      joinOrg = true;
    }

    if (authResult.idTokenPayload[`${URL}/is_new`]) {
      nprogress.start();
      joinOrg = false;
      await store.dispatch("user/REGISTER_AUTH0", { payload });
    }

    if (!migrated.migrated && migrated.is_user_exist) {
      nprogress.start();

      const res = await common.axios.get(common.apiURL + "/meta/users/merge", {
        params: {
          email: authResult.idTokenPayload.email,
        },
      });

      // router.push("/dashboard");
    } else {
      if (authResult.idTokenPayload[`${URL}/has_multiple_auth_providers`]) {
        await store.commit("user/SET_STATE", {
          mergeAccount: true,
        });
      }
    }

    if (joinOrg) {
      await store.dispatch("user/REGISTER_AUTH0", { payload });
    }

    if (authResult.idTokenPayload[`${URL}/is_new`]) {
      router.push("/welcome");
    } else {
      router.push("/dashboard");
    }
  }
  async Normallogin(authResult) {
    nprogress.start();
    const vm = this;
    await vm.localLogin(authResult);
    const payload = {
      orgName: null,
      email: null,
      token: null,
    };
    let state = authResult.state.split(" ");
    if (state.length === 2) {
      payload.token = state[1];
    }
    if (`${URL}/is_new` in authResult.idTokenPayload) {
      payload.orgName = authResult.idTokenPayload[`${URL}/organization`];
      payload.email = authResult.idTokenPayload.email;
    }

    if (authResult.idTokenPayload[`${URL}/is_new`]) {
      nprogress.start();
      await store.dispatch("user/REGISTER_AUTH0", { payload });
    }

    router.push("/dashboard");
  }
  logOut() {
    return new Promise((resolve, reject) => {
      this.idToken = null;
      this.accessToken = null;
      this.tokenExpiry = null;
      this.profile = null;

      this.lock.logout({
        returnTo: window.location.origin,
      });

      localStorage.removeItem(localStorageKey);
      resolve();
    });
  }
}

export default new AuthService();
