<template>
  <div class="base-table-main">
    <header v-if="title || description">
      <!-- <h5 class="table-header">
        {{ header }}
      </h5> -->
      <BaseText :value="title" class="table-header" type="h6" />
      <BaseText
        :value="description"
        class="table-description text-gray-5"
        type="p-l"
      />
    </header>
    <a-table
      v-bind="$attrs"
      v-on="$listeners"
      :class="
        dataLength > 5
          ? 'alternate table-borders'
          : 'not-alternate table-borders'
      "
      v-if="columnBorder"
      :scroll="{ x: 'max-content' }"
      :pagination="
        dataLength > pageSize &&
        isPaginationVisible && {
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} results`,
          total: dataLength,
          pageSize: pageSize,
          style: 'width: 100%; text-align: right;',
          locale: { items_per_page: 'rows per page' },

        }
      "
    >
      <template slot="title" v-if="isTitleVisible === true">
        <div class="above-table">
          <a-row type="flex" justify="space-between">
            <a-col>
              <BaseText :value="header" type="h6" />
            </a-col>
            <a-col>
              <base-button
                v-if="vIf"
                @click="clickBtn()"
                :disabled="disabled"
                category="primary"
                :value="btnValue"
              />
            </a-col>
          </a-row>
        </div>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </a-table>
    <a-table
      v-else
      v-bind="$attrs"
      v-on="$listeners"
      :class="
        dataLength > 5
          ? 'alternate remove-table-borders'
          : 'not-alternate remove-table-borders'
      "
      :scroll="{ x: 'max-content' }"
      :pagination="
        dataLength > pageSize &&
        isPaginationVisible && {
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} results`,
          total: dataLength,
          pageSize: pageSize,
          style: 'width: 100%; text-align: right;',
          locale: { items_per_page: 'rows per page' },
        }
      "
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </a-table>
  </div>
</template>

<script>
import { Table } from "ant-design-vue";
import BaseText from "./BaseText.vue";

export default {
  name: "BaseTable",
  props: {
    title: String,
    description: String,
    columnBorder: Boolean,
    header: String,
    btnValue: String,
    clickBtn: Function,
    disabled: Boolean,
    vIf: String,
    isTitleVisible: Boolean,
    dataLength: Number,
    pageSize: Number,
    isPaginationVisible: Boolean,
  },
  components: {
    "a-table": Table,
    BaseText,
  },
  inheritAttrs: false,
};
</script>
<style lang="scss" scoped>
@import url("./styles/BaseTable.scss");

.base-table-main {
  border: 1px solid #2c3a4725;
  padding-bottom: 1.5em;
}

.above-table {
  padding-left: 2em;
  padding-right: 2em;
}
</style>
