export const frontendFeatures = {
  "dashboard-logo-url-dark":
    "https://ik.imagekit.io/economize/economize-black_j6jdJ0w3X.png?updatedAt=1623089224499",
  "dashboard-logo-url": "resources/images/economize-white.png",
  "login-logo-url": "/resources/images/economize-white.png",
  "login-logo-url-dark": "/resources/images/economize-black.png",
  "login-page": "/login",
  disallowedIntegrations: [],
  disallowedMenuItems: [],
  "external-vault-enabled": false,
  "social-links": [{ linkedin: "" }, { facebook: "" }, { twitter: "" }],
  "theme-switcher-enabled": "false",
  "favicon-url": "https://ik.imagekit.io/economize/favicon_On2RChhdT.ico",
  showForecast: false,
};
