<template>
  <a-drawer v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </a-drawer>
</template>
<script>
import { Drawer } from "ant-design-vue";
export default {
  name: "BaseDrawer",
  inheritAttrs: false, // optional
  components: {
    "a-drawer": Drawer,
  },
};
</script>
