<script>
import Chart from "chart.js/auto";
import { Pie, mixins } from "vue-chartjs";
import common from "@/views/dashboard/common";
const { reactiveProp } = mixins;
import ChartDataLabels from "chartjs-plugin-datalabels";

const color = common.lightChartColors;
Chart.register(ChartDataLabels);

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    chartTitleDisplay: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      default: undefined,
    },
    tooltipCallBackFunc: {
      type: Object,
      default: {
        label: (context) => {
          const label = context.label;
          const data = context.parsed;
          const value = common.formatWithCurrency(data);
          return `${label}: ${value}`;
        },
      },
    },
    dataLabels: {
      type: Boolean,
      default: false,
    },
    dataLabelsFormatter: {
      type: Function,
    },
  },
  methods: {
    renderChartFunc() {
      let ctx = this.$refs.canvas.getContext("2d");

      this.chartData.datasets = this.chartData.datasets.map((item, index) => {
        return {
          ...item,
          backgroundColor: [
            color[0],
            color[1],
            color[2],
            color[3],
            color[4],
            color[5],
            color[6],
            color[7],
            color[8],
            color[9],
          ],
          hoverOffset: 4,
        };
      });

      // Configuration for Pie Charts
      const options = {
        type: "pie",
        data: this.chartData,
        plugins: [ChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: { intersect: false },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          animation: {
            x: {
              duration: 300,
              from: 0,
            },
            y: {
              duration: 300,
              from: 500,
            },
          },
          plugins: {
            datalabels: {
              display: this.dataLabels,
              display: (function (dataLabels) {
                return function (context) {
                  let total = context.dataset.data.reduce((a, b) => a + b);
                  let slice = context.dataset.data[context.dataIndex];
                  let fraction = slice / total;
                  return dataLabels && fraction > 0.1;
                };
              })(this.dataLabels),
              color: "#fff",
              font: {
                size: 10,
                family: "Inter",
                weight: 500,
              },
              formatter: this.dataLabelsFormatter,
            },
            legend: {
              display: true,
              labels: {
                boxWidth: 8,
                boxWidth: 8,
                display: true,
                usePointStyle: true,
                pointStyle: "circle",
                padding: 10,
                font: {
                  size: 10,
                  family: "Inter",
                },
              },
              position: "right",
            },
            tooltip: {
              mode: "index",
              intersect: false,
              position: "nearest",
              backgroundColor: "rgba(0,0,0,0.7)",
              caretSize: 7,
              caretPadding: 5,
              cornerRadius: 10,
              titleFontFamily: "Inter",
              bodyFontFamily: "Inter",
              padding: 10,
              usePointStyle: false,
              titleColor: "#fff",
              borderColor: "rgba(54,54,54,0.20)",
              borderWidth: 2.5,
              callbacks: this.tooltipCallBackFunc,
            },
            title: {
              display: this.chartTitleDisplay,
              text: this.chartTitle,
            },
          },
        },
      };

      new Chart(ctx, options);
    },
  },

  mounted() {
    // Chart.defaults.font.family = "Inter";
    // Chart.defaults.font.weight = "500";
    this.renderChartFunc();
  },
};
</script>
