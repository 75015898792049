var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g(_vm._b({style:([
    _vm.color && {
      color: _vm.theme.color[_vm.color] ? _vm.theme.color[_vm.color] : _vm.color,
    },
    _vm.bg && {
      backgroundColor: _vm.theme.color[_vm.bg] ? _vm.theme.color[_vm.bg] : _vm.bg,
    },
    _vm.theme.elevation[_vm.elevation] && { boxShadow: _vm.theme.elevation[_vm.elevation] },
    _vm.cursor && { cursor: _vm.cursor },
    _vm.overflow && { overflow: _vm.overflow },
    _vm.textAlign && { textAlign: _vm.textAlign },
    _vm.border && { border: (_vm.border + "px") },
    _vm.w && { width: typeof _vm.w === 'number' ? (_vm.w + "rem") : _vm.w },
    _vm.h && { height: typeof _vm.h === 'number' ? (_vm.h + "rem") : _vm.h },
    _vm.textDecoration && { textDecoration: _vm.textDecoration },
    _vm.m && { margin: typeof _vm.m === 'number' ? (_vm.m + "rem") : _vm.m },
    _vm.p && { padding: typeof _vm.p === 'number' ? (_vm.p + "rem") : _vm.p },
    _vm.mt && { marginTop: typeof _vm.mt === 'number' ? (_vm.mt + "rem") : _vm.mt },
    _vm.mb && { marginBottom: typeof _vm.mb === 'number' ? (_vm.mb + "rem") : _vm.mb },
    _vm.ml && { marginLeft: typeof _vm.ml === 'number' ? (_vm.ml + "rem") : _vm.ml },
    _vm.mr && { marginRight: typeof _vm.mr === 'number' ? (_vm.mr + "rem") : _vm.mr },
    _vm.pt && { paddingTop: typeof _vm.pt === 'number' ? (_vm.pt + "rem") : _vm.pt },
    _vm.pb && { paddingBottom: typeof _vm.pb === 'number' ? (_vm.pb + "rem") : _vm.pb },
    _vm.pl && { paddingLeft: typeof _vm.pl === 'number' ? (_vm.pl + "rem") : _vm.pl },
    _vm.pr && { paddingRight: typeof _vm.pr === 'number' ? (_vm.pr + "rem") : _vm.pr },
    _vm.borderRadius && {
      borderRadius: (_vm.borderRadius + "rem"),
    },
    _vm.borderBottom && {
      borderBottom: _vm.borderBottom,
    },
    _vm.borderTop && {
      borderTop: _vm.borderTop,
    },
    _vm.fontSize && { fontSize: (_vm.fontSize + "rem") },
    _vm.maxW && { maxWidth: typeof _vm.maxW === 'number' ? (_vm.maxW + "rem") : _vm.maxW },
    _vm.maxH && { maxHeight: typeof _vm.maxH === 'number' ? (_vm.maxH + "rem") : _vm.maxH },
    _vm.minW && { minWidth: typeof _vm.minW === 'number' ? (_vm.minW + "rem") : _vm.minW },
    _vm.minH && { minHeight: typeof _vm.minH === 'number' ? (_vm.minH + "rem") : _vm.minH },
    _vm.opacity && { opacity: _vm.opacity },
    _vm.whiteSpace && { whiteSpace: _vm.whiteSpace } ])},'div',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }