<template>
  <a-tooltip v-bind="$attrs" v-on="$listeners">
    <template slot="title">
      <slot name="title" />
    </template>
    <slot />
  </a-tooltip>
</template>
<script>
import { Tooltip } from "ant-design-vue";
export default {
  name: "BaseTooltip",
  inheritAttrs: false, // optional
  components: {
    "a-tooltip": Tooltip,
  },
};
</script>
