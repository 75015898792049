<template>
  <div class="base-text-wrapper" :style="[textAlign && { textAlign }]">
    <span
      :style="[
        theme.typography[type]
          ? theme.typography[type]
          : theme.typography['p-l'],
        theme.elevation[elevation] && { boxShadow: theme.elevation[elevation] },
        fontColor && { color: fontColor },
        textDecoration && { textDecoration: textDecoration },
        theme.color[color] && {
          color: theme.color[color],
        },
        whiteSpace && { whiteSpace: whiteSpace },
      ]"
    >
      {{ value }}
    </span>
  </div>
</template>

<script>
import theme from "@/components/theme";

export default {
  data() {
    return {
      theme,
    };
  },
  name: "BaseText",
  props: {
    value: [String, Number],
    elevation: String,
    fontColor: String,
    color: String,
    type: String,
    textDecoration: {
      type: String,
      default: "none",
    },
    whiteSpace: String,
    textAlign: {
      type: String,
      default: null,
    },
  },
};
</script>
