<template>
  <Multiselect v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
};
</script>

<style lang="scss">
.multiselect__tags {
  border-radius: 4px !important;

  border: none !important;
}

.multiselect--disabled > .multiselect__tags {
  background: var(--e-ui-gray-2) !important;
  width: 100% !important;
}
.multiselect--disabled > .multiselect__select {
  background: var(--e-ui-gray-2) !important;
}
.multiselect--disabled > .multiselect__tags > .multiselect__single {
  background: var(--e-ui-gray-2) !important;
}
.multiselect {
  transition: all 0.3s !important;
  cursor: pointer;
  border: 0.25px solid var(--e-ui-gray-2) !important;
  box-shadow: var(--e-ui-elevation-1) !important;
  border-radius: 6px !important;
  color: var(--e-ui-gray-5) !important;

  &::placeholder {
    color: var(--e-ui-gray-5) !important;
  }

  &:placeholder-shown {
    color: var(--e-ui-gray-5) !important;
  }

  &:focus {
    border: 0.4px solid var(--e-ui-blue-2) !important;
    box-shadow: var(--e-ui-drop-shadow-hover) !important;
    transition: all 0.3s ease !important;
  }
  &:hover {
    border: 0.4px solid var(--e-ui-blue-2) !important;
    box-shadow: var(--e-ui-drop-shadow-hover) !important;
    transition: all 0.3s ease !important;
  }

  &:disabled {
    background: var(--e-ui-gray-2) !important;
    &:focus {
      border: none;
      box-shadow: var(--e-ui-elevation-1) !important;
      transition: all 0.3s ease !important;
    }
    &:hover {
      border: none;
      box-shadow: var(--e-ui-elevation-1) !important;
      transition: all 0.3s ease !important;
    }
  }
}

.multiselect__content-wrapper {
  top: 3.6rem;
  border: 1px solid var(--e-ui-gray-2) !important;
  box-shadow: var(--e-ui-elevation-2) !important;
  border-radius: 4px !important;
}
.multiselect__single {
  color: var(--e-ui-gray-6) !important;
}
.multiselect__element {
  border-bottom: 1px solid var(--e-ui-gray-2) !important;
  border-left: 2px solid #fff !important;

  &:hover {
    background-color: var(--e-ui-blue-1) !important;
    color: var(--e-ui-blue-5) !important;
    border-left: 2px solid var(--e-ui-blue-5) !important;
  }
}

.multiselect__option--selected {
  margin-left: -2px !important;
  background-color: var(--e-ui-blue-1) !important;
  color: var(--e-ui-blue-5) !important;
  font-weight: normal !important;
  border-left: 2px solid var(--e-ui-blue-5);
}

.multiselect__option--highlight {
  background-color: inherit !important;
  color: inherit !important;
}

.multiselect--disabled {
  background: var(--e-ui-gray-2) !important;
}
</style>
