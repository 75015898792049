var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.category === 'date-picker')?_c('span',[_c('DatePicker',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('base-input',_vm._g({attrs:{"category":"text-date","readOnly":"","value":((inputValue.start) + " - " + (inputValue.end)),"widthSize":_vm.widthSize}},inputEvents.start))]}}],null,false,2475100556)},'DatePicker',_vm.$attrs,false),_vm.$listeners))],1):(_vm.category === 'a-date-picker')?_c('span',[_c('a-date-picker',_vm._g(_vm._b({style:([
        _vm.maxW && { maxWidth: (_vm.maxW + "rem") },
        { height: _vm.h ? _vm.h : '44px !important' } ])},'a-date-picker',_vm.$attrs,false),_vm.$listeners))],1):(_vm.category === 'a-range-picker')?_c('span',[_c('a-range-picker',_vm._g(_vm._b({style:([
        _vm.maxW && { maxWidth: (_vm.maxW + "rem") },
        { height: _vm.h ? _vm.h : '44px !important' } ])},'a-range-picker',_vm.$attrs,false),_vm.$listeners))],1):(_vm.category === 'el-date-picker')?_c('span',[_c('el-date-picker',_vm._g(_vm._b({style:([
        _vm.maxW && { maxWidth: (_vm.maxW + "rem") },
        { height: _vm.h ? _vm.h : '44px !important' } ])},'el-date-picker',_vm.$attrs,false),_vm.$listeners))],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }