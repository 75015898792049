<template>
  <div>
    <span v-if="category === 'date-picker'">
      <DatePicker v-bind="$attrs" v-on="$listeners">
        <template v-slot="{ inputValue, inputEvents }">
          <base-input
            category="text-date"
            readOnly
            :value="`${inputValue.start} - ${inputValue.end}`"
            :widthSize="widthSize"
            v-on="inputEvents.start"
          />
        </template>
      </DatePicker>
    </span>
    <span v-else-if="category === 'a-date-picker'">
      <a-date-picker
        :style="[
          maxW && { maxWidth: `${maxW}rem` },
          { height: h ? h : '44px !important' },
        ]"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </span>
    <span v-else-if="category === 'a-range-picker'">
      <a-range-picker
        :style="[
          maxW && { maxWidth: `${maxW}rem` },
          { height: h ? h : '44px !important' },
        ]"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </span>
    <span v-else-if="category === 'el-date-picker'">
      <el-date-picker
        :style="[
          maxW && { maxWidth: `${maxW}rem` },
          { height: h ? h : '44px !important' },
        ]"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </span>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/date-picker.css";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

// configure language
locale.use(lang);

import { DatePicker as AdatePicker } from "ant-design-vue";
import BaseInput from "./BaseInput";
export default {
  inheritAttrs: false,
  props: ["widthSize", "category", "maxW", "h"],
  components: {
    DatePicker: () => import("v-calendar/lib/components/date-picker.umd"),
    "a-date-picker": AdatePicker,
    "a-range-picker": AdatePicker.RangePicker,
    "el-date-picker": () => import("element-ui/lib/date-picker"),
    "base-input": BaseInput,
  },
  computed: {
    showModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.ant-calendar-panel {
  font-family: var(--e-font-family);
}
.ant-calendar-range-picker-separator {
  vertical-align: initial !important;
}
.ant-calendar-picker-input,
.ant-input,
.el-date-editor {
  border: 0.25px solid var(--e-ui-gray-2) !important;
  box-shadow: var(--e-ui-elevation-1) !important;
  border-radius: 6px !important;
  color: var(--e-ui-gray-5) !important;

  &::placeholder {
    color: var(--e-ui-gray-5) !important;
  }

  &:placeholder-shown {
    color: var(--e-ui-gray-5) !important;
  }

  &:focus {
    border: 0.4px solid var(--e-ui-blue-2) !important;
    box-shadow: var(--e-ui-drop-shadow-hover) !important;
    transition: all 0.3s ease !important;
  }
  &:hover {
    border: 0.4px solid var(--e-ui-blue-2) !important;
    box-shadow: var(--e-ui-drop-shadow-hover) !important;
    transition: all 0.3s ease !important;
  }

  &:disabled {
    background: var(--e-ui-gray-2) !important;
    &:focus {
      border: none;
      box-shadow: var(--e-ui-elevation-1) !important;
      transition: all 0.3s ease !important;
    }
    &:hover {
      border: none;
      box-shadow: var(--e-ui-elevation-1) !important;
      transition: all 0.3s ease !important;
    }
  }
}
</style>
