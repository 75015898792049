import configs from "./configs.json";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Temp setup, move to LD
let firebaseConfig = configs[process.env.VUE_APP_WL_USER || "economize"];
if (process.env.VUE_APP_WL_USER === "economize") {
  firebaseConfig = firebaseConfig[process.env.VUE_APP_MODE];
}

const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const firebaseDatabase = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const FirebaseAuth = getAuth(app);
