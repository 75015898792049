<template>
  <span class="button-wrapper">
    <Button
      v-if="!href"
      :disabled="disabled"
      :class="`btn btn-${category}`"
      :size="size"
      :loading="loading"
      :style="styles"
      @click="handleClick"
      :html-type="htmlType"
    >
      <Icon v-if="icon && iconAlign === 'left'" :type="icon" />{{ value }}
      <Icon v-if="iconAlign === 'right'" :type="icon" />
    </Button>

    <router-link :to="href" v-else>
      <Button
        :disabled="disabled"
        :class="`btn-${category}`"
        :size="size"
        :loading="loading"
        :style="styles"
        @click="handleClick"
      >
        <Icon v-if="icon && iconAlign === 'left'" :type="icon" />{{ value }}
        <Icon v-if="iconAlign === 'right'" :type="icon" />
      </Button>
    </router-link>
  </span>
</template>

<script>
import { Button, Icon } from "ant-design-vue";
import theme from "@/components/theme";

export default {
  data() {
    return {
      theme,
    };
  },
  name: "BaseButton",
  components: {
    Button,
    Icon,
  },
  props: {
    /**
     * Align icon relative to text in button `left` | `right`
     */
    iconAlign: {
      type: String,
      default: "left",
    },
    htmlType: {
      type: String,
      default: "button",
    },
    href: {
      type: String,
      default: "",
    },
    styles: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "primary",
    },
    /**
     * `large` | `default` | `small` |
     */
    size: {
      type: String,
      default: "default",
    },
    /**
     * Any color of background button
     */
    color: String,
    /**
     * Any color of Text button
     */
    textColor: String,
    /**
     * Value of Button
     */
    value: String,
    /**
     * Icon
     */
    icon: String,
    changePathTo: String,
    /**
     * set the loading status of button
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * disabled state of button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(value) {
      this.$emit("click", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin generic-disabled {
  background-color: var(--e-ui-gray-3);
  color: var(--e-ui-gray-4);
  border: none;

  &:hover {
    background-color: var(--e-ui-gray-3);
    color: var(--e-ui-gray-4);
    border: none;
  }
}

.btn {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.btn-primary {
  background-color: var(--e-ui-blue-5);
  color: #ffffff;
  border: none;
  border-radius: 0.25rem !important;
  font-weight: 600 !important;
  transition: none !important;

  &:hover {
    background-color: var(--e-ui-blue-7);
    color: #ffffff;
    border: none;
  }
  &:focus {
    background-color: var(--e-ui-blue-7);
    color: #ffffff;
    border: none;
  }
  &:disabled {
    @include generic-disabled;
  }
}

.btn-secondary {
  border: 1px solid var(--e-ui-gray-3);
  color: black;
  border-radius: 0.25rem !important;
  font-weight: 600;
  transition: none !important;

  &:hover {
    background-color: var(--e-ui-gray-0-5);
    border: 1px solid var(--e-ui-gray-3);
    color: black;
  }
  &:focus {
    background-color: var(--e-ui-gray-0-5);
    border: 1px solid var(--e-ui-gray-3);
    color: black;
  }
  &:disabled {
    @include generic-disabled;
  }
}
.btn-danger {
  background-color: var(--e-ui-red-5);
  border-radius: 0.25rem !important;
  border: none;
  color: #ffffff;

  transition: none !important;

  &:hover {
    background-color: var(--e-ui-red-7);
    color: #ffffff;
    border: none;
  }
  &:focus {
    background-color: var(--e-ui-red-7);
    color: #ffffff;
    border: none;
  }
  &:disabled {
    @include generic-disabled;
  }
}

.btn-danger-secondary {
  border: 1px solid var(--e-ui-red-3);
  color: var(--e-ui-red-5);
  transition: none !important;
  border-radius: 0.25rem !important;

  &:hover {
    background-color: var(--e-ui-red-0-5);
    border: 1px solid var(--e-ui-red-3);
    color: var(--e-ui-red-5);
  }
  &:focus {
    background-color: var(--e-ui-red-0-5);
    border: 1px solid var(--e-ui-red-3);
    color: var(--e-ui-red-5);
  }
  &:disabled {
    @include generic-disabled;
  }
}
</style>
