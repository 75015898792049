<template>
  <div id="app">
    <localization></localization>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import Localization from "@/localization";
import store from "@/store";
import { frontendFeatures } from "./WtConstants";
import VueMeta from "vue-meta";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

Vue.use(VueMeta);
export default {
  name: "app",
  metaInfo: {
    title: "Economize",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        property: "og:title",
        content: "Economize",
      },
      {
        property: "og:description",
        content:
          "Economize provides companies running on public cloud a simple way to analyze, manage and optimize their cloud costs & billing.",
      },
      {
        property: "og:type",
        content: "google cloud billing",
      },
      {
        property: "og:site_name",
        content: "Economize – Demystify Cloud Costs",
      },
      {
        name: "keywords",
        content:
          "cloud-budget, cloud-alerts, cloud-optimization, cloud-visibility, google-cloud-pricing, google-compute-engine-pricing, cloud-costs-demystified, cloud-billing, gcp-billing, gcp-costs-analysis, compute engine pricing, economize, cloud-spending, gcp-costs",
      },
      {
        name: "copyright",
        content: "Economize Cloud",
      },
    ],
  },
  components: { Localization },
  computed: {
    ...mapState(["settings"]),
    ...mapState("user", ["authorized"]),
    nextRoute() {
      return this.$route.query.redirect || store.state.user.mergeAccount
        ? "/auth/merge-account"
        : "/";
    },
    currentRoute() {
      return this.$route.path;
    },
  },
  beforeMount() {
    let link = document.querySelector("link[rel~='icon']");
    // if suppose the link tag is not mounted properly or not present
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
    }
    if (!store.state.user.maintenanceMode) {
      const frontendConfigs = store.state.user.features.variation(
        "frontend-features",
        frontendFeatures
      );
      link.href = frontendConfigs["favicon-url"];
      document.getElementsByTagName("head")[0].appendChild(link);
      store.state.settings.theme = frontendConfigs.theme;
    } else if (process.env.VUE_APP_WL_USER === "economize") {
      link.href = frontendFeatures["favicon-url"];
      document.getElementsByTagName("head")[0].appendChild(link);
      store.state.settings.theme = "light";
    }
    let frontendVersion = localStorage.getItem("frontendVersion");
    if (
      frontendVersion !== process.env.VUE_APP_VERSION &&
      frontendVersion !== null
    ) {
      // clear orgId & selected project
      localStorage.removeItem("organization_id");
      localStorage.removeItem("selectedProject");
      // set frontend version to current version
      localStorage.setItem("frontendVersion", process.env.VUE_APP_VERSION);
    } else if (frontendVersion === null) {
      // clear orgId & selected project
      localStorage.removeItem("organization_id");
      localStorage.removeItem("selectedProject");
      // create new instance of frontendVersion
      localStorage.setItem("frontendVersion", process.env.VUE_APP_VERSION);
    }
  },
  mounted() {
    // freshchat
    const initFreshChat = () => {
      if (process.env.VUE_APP_MODE !== "dev") {
        window.fcWidget.init({
          token: "f7e27911-6899-4f86-b6b9-4b7047fe334e",
          host: "https://wchat.freshchat.com",
          siteId: "economize",
        });
      }
    };

    const initialize = (i, t) => {
      var e;
      // eslint-disable-next-line
      i.getElementById(t)
        ? initFreshChat()
        : (((e = i.createElement("script")).id = t),
          (e.async = !0),
          (e.src = "https://wchat.freshchat.com/js/widget.js"),
          (e.onload = initFreshChat),
          i.head.appendChild(e));
    };

    const initiateCall = () => {
      initialize(document, "freshchat-js-sdk");
    };

    if (!store.state.user.maintenanceMode) {
      const frontendConfigs = store.state.user.features.variation(
        "frontend-features",
        frontendFeatures
      );
      if (!frontendConfigs.disallowedIntegrations.includes("headway")) {
        initiateCall();
      }
    }
    // if user is authorized set the userID and email to sentry
    if (
      this.$store?.state?.user?.authorized &&
      process.env.VUE_APP_MODE !== "dev"
    ) {
    }
    // this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
    this.$store.commit("SET_PRIMARY_COLOR", {
      color: this.settings.primaryColor,
    });
    this.$store.commit("SET_THEME", { theme: this.settings.theme });

    var loginStatus = localStorage.getItem("isLoggedIn");
    if (loginStatus === "true") {
      this.$cookies.set("userLoggedIn", "true", "economize.cloud");
    } else if (loginStatus !== "true") {
      this.$cookies.set("userLoggedIn", "false", "economize.cloud");
    }
  },
  watch: {
    "$store.state.settings.theme"(theme) {
      this.$store.commit("SET_THEME", { theme });
    },
    authorized(authorized) {
      if (authorized && this.currentRoute === "/login") {
        this.$router.replace(this.nextRoute);
      }
    },
    $route(to, from) {
      const query = Object.assign({}, to.query);
      this.$store.commit("SETUP_URL_SETTINGS", query);
    },
  },
};
</script>
