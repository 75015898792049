var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-table-main"},[(_vm.title || _vm.description)?_c('header',[_c('BaseText',{staticClass:"table-header",attrs:{"value":_vm.title,"type":"h6"}}),_c('BaseText',{staticClass:"table-description text-gray-5",attrs:{"value":_vm.description,"type":"p-l"}})],1):_vm._e(),(_vm.columnBorder)?_c('a-table',_vm._g(_vm._b({class:_vm.dataLength > 5
        ? 'alternate table-borders'
        : 'not-alternate table-borders',attrs:{"scroll":{ x: 'max-content' },"pagination":_vm.dataLength > _vm.pageSize &&
      _vm.isPaginationVisible && {
        showSizeChanger: true,
        showTotal: function (total, range) { return ("Showing " + (range[0]) + " to " + (range[1]) + " of " + total + " results"); },
        total: _vm.dataLength,
        pageSize: _vm.pageSize,
        style: 'width: 100%; text-align: right;',
        locale: { items_per_page: 'rows per page' },

      }},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'a-table',_vm.$attrs,false),_vm.$listeners),[(_vm.isTitleVisible === true)?_c('template',{slot:"title"},[_c('div',{staticClass:"above-table"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('BaseText',{attrs:{"value":_vm.header,"type":"h6"}})],1),_c('a-col',[(_vm.vIf)?_c('base-button',{attrs:{"disabled":_vm.disabled,"category":"primary","value":_vm.btnValue},on:{"click":function($event){return _vm.clickBtn()}}}):_vm._e()],1)],1)],1)]):_vm._e()],2):_c('a-table',_vm._g(_vm._b({class:_vm.dataLength > 5
        ? 'alternate remove-table-borders'
        : 'not-alternate remove-table-borders',attrs:{"scroll":{ x: 'max-content' },"pagination":_vm.dataLength > _vm.pageSize &&
      _vm.isPaginationVisible && {
        showSizeChanger: true,
        showTotal: function (total, range) { return ("Showing " + (range[0]) + " to " + (range[1]) + " of " + total + " results"); },
        total: _vm.dataLength,
        pageSize: _vm.pageSize,
        style: 'width: 100%; text-align: right;',
        locale: { items_per_page: 'rows per page' },
      }},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'a-table',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }