<script>
import Chart from "chart.js/auto";
import { Bar, mixins } from "vue-chartjs";
import common from "@/views/dashboard/common";
const { reactiveProp } = mixins;
import "chartjs-plugin-style";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

const ConvertWaterfallData = (arr) => {
  const convertData = [];
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      convertData.push(arr[i]);
    } else {
      convertData.push([arr[i - 1], arr[i]]);
    }
  }
  return convertData;
};

const UpAndDownColorBar = (arr) => {
  const colors = [];
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      colors.push("#238365");
    } else {
      colors.push(arr[i - 1] - arr[i] > 0 ? "#F5222D" : "#238365");
    }
  }
  return colors;
};

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    tooltipCallbackFunc: {
      type: Object,
      default: null,
    },
    yAxesCallbackFunc: {
      type: Function,
      default: (val) => val,
    },
    onClickLegendIncludeData: {
      type: Boolean,
      default: false,
    },
    xAxisDateFormat: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
    },
    chartTitleDisplay: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      default: null,
    },
    xAxisTitleDisplay: {
      type: Boolean,
      default: false,
    },
    yAxisTitleDisplay: {
      type: Boolean,
      default: false,
    },
    xAxisTitleText: {
      type: String,
      default: null,
    },
    yAxisTitleText: {
      type: String,
      default: null,
    },
  },
  methods: {
    renderChartFunc() {
      let ctx = this.$refs.canvas.getContext("2d");

      this.chartData.datasets = this.chartData.datasets.map((item, index) => {
        return {
          ...item,
          fill: true,
          pointStyle: "circle",
          backgroundColor: UpAndDownColorBar(item.data),
          borderColor: UpAndDownColorBar(item.data),
          pointBackgroundColor: UpAndDownColorBar(item.data),
          barThickness: 45,
          pointHoverRadius: 7,
          pointBorderWidth: 1,
          pointHoverBorderWidth: 4,
          pointHoverBackgroundColor: "rgb(255, 255, 255)",
          categoryPercentage: 1.0,
          barPercentage: 0.7,
          data: ConvertWaterfallData(item.data),
        };
      });

      let xAxis = this.xAxisDateFormat;
      let isDataHidden = false;

      // Configuration for Bar Charts
      const options = {
        plugins: [ChartDataLabels],

        type: "bar",
        data: this.chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 20,
          elements: {
            line: {
              tension: 0,
            },
            point: {
              display: true,
              backgroundColor: "rgb(252, 152, 3)",
              radius: 0,
              hitRadius: 5,
              hoverRadius: 7,
            },
          },
          pointStyle: "circle",
          hover: { intersect: false },
          scales: {
            x: {
              stacked: this.stacked ? true : false,
              display: true,
              color: "rgb(134, 147, 157)",
              borderDash: [2, 2],
              grid: {
                zeroLineColor: "rgba(217, 217, 217,0.25)",
                color: "rgba(217, 217, 217,0.6)",
                borderDash: [2, 2],
                drawTicks: false,
              },
              ticks: {
                beginAtZero: true,
                font: {
                  size: 12,
                  family: "Inter",
                  color: "rgb(134, 147, 157)",
                },
                padding: 10,
                fontFamily: "Inter",
                maxTicksLimit: 15,
                autoSkip: true,
                maxRotation: 0,
                minRotation: 30,
                callback: function (value) {
                  const data = this.getLabelForValue(value);
                  const [year, month, day] = data
                    .split("-")
                    .map((s) => s.trim());
                  const timeStamp =
                    day +
                    " " +
                    common.utils.monthNamesShort[parseInt(month) - 1] +
                    " " +
                    year;
                  return xAxis ? timeStamp : data;
                },
              },
              pointLabels: {
                fontFamily: "Inter",
              },
              title: {
                display: this.xAxisTitleDisplay,
                text: this.xAxisTitleText,
              },
            },
            y: {
              stacked: this.stacked ? true : false,
              display: true,
              color: "rgb(134, 147, 157)",
              grid: {
                zeroLineColor: "rgba(238, 238, 238,0.6)",
                drawTicks: false,
                color: "rgba(238, 238, 238,0.6)",
              },
              ticks: {
                beginAtZero: true,
                callback: this.yAxesCallbackFunc,
                font: {
                  size: 12,
                  family: "Inter",
                  color: "rgb(134, 147, 157)",
                },
                padding: 10,
                maxTicksLimit: 8,
              },
              pointLabels: {
                fontFamily: "Inter",
              },
              title: {
                display: this.yAxisTitleDisplay,
                text: this.yAxisTitleText,
              },
            },
          },
          animation: {
            x: {
              duration: 1500,
              from: 0,
            },
            y: {
              duration: 1500,
              from: 500,
            },
          },
          plugins: {
            datalabels: {
              formatter: function (value, context, data) {
                return common.formatWithCurrencyUnit(
                  Array.isArray(value) ? value[1] - value[0] : value
                );
              },
              font: {
                size: 10,
                family: "Inter",
                weight: 500,
              },
              display: true,
              color: "black",
              labels: {
                title: {
                  font: {
                    weight: "bold",
                  },
                },
              },
            },
            legend: {
              display: true,
              labels: {
                boxWidth: 10,
                boxWidth: 10,
                display: true,
                usePointStyle: true,
                pointStyle: "circle",
                padding: 10,
                font: {
                  size: 12,
                  family: "Inter",
                  color: "rgb(134, 147, 157)",
                },
                generateLabels: (chart) => {
                  let visibility = [];
                  for (let i = 0; i < chart.data.datasets.length; i++) {
                    if (chart.isDatasetVisible(i)) {
                      visibility.push("#000000");
                    } else {
                      visibility.push("rgba(102,102,102,0.5");
                    }
                  }
                  return chart.data.datasets.map((labelText, index) => ({
                    text: labelText.label,
                    fillStyle: labelText.backgroundColor,
                    strokeStyle: labelText.backgroundColor,
                    fontColor: visibility[index],
                  }));
                },
              },
              position: "bottom",
              onClick: this.onClickLegendIncludeData
                ? function (e, legendItem, legend) {
                    let index;
                    legend.chart.data.datasets.map((text, i) => {
                      if (text.label === legendItem.text) {
                        index = i;
                      }
                    });
                    let ci = this.chart;
                    let alreadyHidden =
                      ci.getDatasetMeta(index).hidden === null
                        ? false
                        : ci.getDatasetMeta(index).hidden;
                    let anyOthersAlreadyHidden = false;
                    let allOthersHidden = true;

                    ci.data.datasets.forEach(function (e, i) {
                      let meta = ci.getDatasetMeta(i);

                      if (i !== index) {
                        if (meta.hidden) {
                          anyOthersAlreadyHidden = true;
                        } else {
                          allOthersHidden = false;
                        }
                      }
                    });

                    if (alreadyHidden) {
                      ci.getDatasetMeta(index).hidden = null;
                    } else {
                      ci.data.datasets.forEach(function (e, i) {
                        var meta = ci.getDatasetMeta(i);

                        if (i !== index) {
                          if (anyOthersAlreadyHidden && !allOthersHidden) {
                            meta.hidden = true;
                          } else {
                            meta.hidden =
                              meta.hidden === null ? !meta.hidden : null;
                          }
                        } else {
                          meta.hidden = null;
                        }
                      });
                    }

                    ci.update();
                  }
                : function (e, legendItem, legend) {
                    const ci = legend.chart;
                    legend.chart.data.datasets.map((text, i) => {
                      if (text.label === legendItem.text) {
                        ci.show(i);
                        legendItem.hidden = false;
                      } else {
                        ci.hide(i);
                        legendItem.hidden = true;
                      }
                    });
                  },
            },
            tooltip: {
              mode: "index",
              intersect: false,
              position: "nearest",
              backgroundColor: "rgba(0,0,0,0.7)",
              caretSize: 10,
              caretPadding: 10,
              cornerRadius: 20,
              titleFontFamily: "Inter",
              bodyFontFamily: "Inter",
              padding: 15,
              usePointStyle: false,
              titleColor: "#fff",
              callbacks: this.tooltipCallbackFunc,
              itemSort: function (a, b) {
                return b.raw - a.raw;
              },
              borderColor: "rgba(54,54,54,0.20)",
              borderWidth: 2.5,
            },
            title: {
              display: this.chartTitleDisplay,
              text: this.chartTitle,
            },
          },
          hiddenSlices: [1, 3],
        },
      };

      const ci = new Chart(ctx, options);
      ci.legend.legendItems.forEach((_, i) => {
        if (i !== 0) {
          ci.setDatasetVisibility(i, false);
        }
      });
      ci.update();
      ci.render();
    },
  },

  mounted() {
    Chart.defaults.font.family = "Inter";
    Chart.defaults.font.weight = "500";
    this.renderChartFunc();
  },
};
</script>
