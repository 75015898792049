import Vue from "vue";
import Router from "vue-router";
import NProgress from "vue-nprogress";
import store from "@/store";
import common from "@/views/dashboard/common";
import { getMenu } from "@/services/menu";
import Meta from "vue-meta";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: () => import("./layouts/Main"),
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // Dashboard
        {
          name: "dashboard-gcp",
          path: "/dashboard/gcp",
          meta: {
            title: "Home",
            breadcrumb: [
              {
                title: "Home",
                name: "Home",
                link: "/dashboard/gcp",
                description:
                  "A brief summary of the project's cloud costs and your monthly cloud spend on services.",
                imageLink:
                  "https://ik.imagekit.io/economize/Content_Home_r3UFH7uHk.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074028012",
              },
            ],
          },
          props: true,
          component: () => import("./views/dashboard/gcp"),
        },
        {
          name: "setting-up-gcp",
          path: "/gcp/setting-up",
          meta: {
            title: "Setting up",
          },
          component: () => import("./views/pages/setting-up/gcp"),
        },
        {
          name: "organisation-list-test",
          path: "/dashboard/gcp/OrganisationList",
          meta: {
            title: "OrganisationListTest",
          },
          component: () =>
            import("./views/dashboard/gcp/tabs/OrganisationList"),
        },
        // {
        //   name: "scheduler"
        //   path: "/dashboard/gcp/scheduler",
        //   meta: {
        //     title: "Scheduler",
        //   },
        //   component: () =>
        //     import("./views/dashboard/gcp/tabs/instance_scheduler"),
        // },
        {
          name: "compute-engine",
          path: "/dashboard/gcp/instances",
          meta: {
            title: "Instances",
            breadcrumb: [
              {
                title: "Explorer",
                name: "Explorer",
                link: "/dashboard/gcp/explorer",
              },
              {
                title: "Compute Engine",
                name: "compute-engine",
                link: "/dashboard/gcp/explorer",
                description:
                  "List of your instances with utilization and respective cost",
              },
            ],
          },
          component: () => import("./views/dashboard/gcp/tabs/instances"),
          props: true,
        },
        {
          name: "storage",
          path: "/dashboard/gcp/storage/:tab?",
          meta: {
            title: "Storage",
            breadcrumb(route) {
              const tab = route.params.tab ?? "sql";

              return [
                {
                  title: "Explorer",
                  name: "Explorer",
                  link: "/dashboard/gcp/explorer",
                },
                {
                  isTab: true,
                  title: {
                    sql: "Cloud SQLs",
                    bucket: "Bucket Storage",
                    memory: "Memory Storage",
                  }[tab],
                  name: "Explorer",
                  link: {
                    sql: "/dashboard/gcp/storage/sql",
                    bucket: "/dashboard/gcp/storage/bucket",
                    memory: "/dashboard/gcp/storage/memory",
                  }[tab],
                  description: {
                    sql: "List of your Cloud SQLs",
                    bucket: "List of your Buckets",
                    memory: "List of your Memory Storages",
                  }[tab],
                },
              ];
            },
          },
          props: true,
          component: () => import("./views/dashboard/gcp/tabs/images"),
        },
        {
          name: "kubernetes-clusters",
          path: "/dashboard/gcp/clusters",
          meta: {
            title: "Kubernetes Clusters",
            breadcrumb: [
              {
                title: "Explorer",
                name: "Explorer",
                link: "/dashboard/gcp/explorer",
              },
              {
                title: "Kubernetes Clusters",
                name: "Kubernetes clusters",
                link: "/dashboard/gcp/clusters",
                description: "Lists of Kubernetes Clusters",
              },
            ],
          },
          component: () => import("./views/dashboard/gcp/tabs/clusters_list"),
        },
        // {
        //   path: "/dashboard/gcp/reports",
        //   meta: {
        //     title: "Configure report subscriptions",
        //   },
        //   component: () => import("./views/dashboard/gcp/tabs/reports"),
        // },
        {
          name: "incidents",
          path: "/dashboard/gcp/incidents",
          meta: {
            title: "Incidents",
            breadcrumb: [
              {
                title: "Incidents",
                name: "Incidents",
                description:
                  "Real-time anomaly detection on your cloud costs that helps prevent accidental cost spikes",
                link: "/dashboard/gcp/incidents",
                imageLink:
                  "https://ik.imagekit.io/economize/Content_Anomaly_alerts_ClIUkCmdY.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074026475",
              },
            ],
          },
          component: () => import("./views/dashboard/gcp/tabs/cost_anomalies"),
        },
        {
          name: "auto-optimize",
          path: "/dashboard/gcp/auto-optimize",
          meta: {
            title: "Auto Optimize",
            breadcrumb: [
              {
                title: "Auto Optimize",
                name: "Auto Optimize",
                description: "",
                link: "/dashboard/gcp/auto-optimize",
              },
            ],
          },
          component: () => import("./views/dashboard/gcp/tabs/auto_optimize"),
        },
        {
          name: "gcp-services",
          path: "/dashboard/gcp/gcp-services/:tab?",
          meta: {
            title: "GCP Services",
            breadcrumb(route) {
              const tab = route.params.tab ?? "logging";
              return [
                {
                  title: "Explorer",
                  name: "Explorer",
                  link: "/dashboard/gcp/explorer",
                },
                {
                  isTab: true,
                  title: {
                    logging: "Cloud Logging",
                    bigquery: "BigQuery",
                  }[tab],
                  name: "Explorer",
                  link: {
                    logging: "/dashboard/gcp/gcp-services/logging",
                    bigquery: "/dashboard/gcp/gcp-services/bigquery",
                  }[tab],
                  description: {
                    logging: "Resource wise logging volume breakdown",
                    bigquery: "List of BigQuery services",
                  }[tab],
                },
              ];
            },
          },
          props: true,
          component: () =>
            import("./views/dashboard/gcp/tabs/managed_gcp_services"),
        },
        {
          name: "custom-views",
          path: "/custom-views",
          meta: {
            title: "Custom Views",
          },
          component: () => import("./views/dashboard/gcp/tabs/custom_views"),
        },
        {
          name: "recommendations",
          path: "/dashboard/gcp/recommendations",
          meta: {
            title: "Recommendations",
            breadcrumb: [
              {
                title: "Recommendations",
                name: "Recommendations",
                description:
                  "Automatically get custom recommendations that are designed for your specific use case. Filter recommendations by category, and dismiss or act upon smart suggestions.",
                link: "/dashboard/gcp/recommendations",
                imageLink:
                  "https://ik.imagekit.io/economize/Content_Recommendations_GXd1-II6Q.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074026716",
              },
            ],
          },
          component: () => import("./views/dashboard/gcp/tabs/recommendations"),
        },
        {
          name: "bigquery-recommendations",
          path: "/dashboard/gcp/recommendations/bigquery",
          meta: {
            title: "BigQuery Recommendations",
            breadcrumb: [
              {
                title: "BigQuery Recommendations",
                name: "BigQuery Recommendations",
                link: "/dashboard/gcp/recommendations/bigquery",
              },
            ],
          },
          component: () =>
            import(
              "./views/dashboard/gcp/tabs/recommendations/BigQueryRecommendations.vue"
            ),
        },
        {
          name: "explorer",
          path: "/dashboard/gcp/explorer/",
          meta: {
            title: "Explorer",
          },
          children: [
            {
              name: "assets-overview",
              path: "/dashboard/gcp/explorer/overview",
              meta: {
                title: "Overview",
                breadcrumb: [
                  {
                    title: "Overview",
                    name: "Overview",
                    link: "/dashboard/gcp/explorer/overview",
                    description:
                      "Your infrastructure is scattered, get in-depth analysis and information on all the resources that you run and understand the unit cost per resource.",
                  },
                ],
              },
              props: true,
              component: () =>
                import("./views/dashboard/gcp/tabs/assets_explorer/Overview"),
            },
            {
              name: "assets-resources",
              path: "/dashboard/gcp/explorer/resources/:tab?",
              meta: {
                title: "Resources",
                breadcrumb: [
                  {
                    title: "Resources",
                    name: "Resources",
                    link: "/dashboard/gcp/explorer/resources",
                    description: "List of all your resources",
                  },
                ],
              },
              props: true,
              component: () =>
                import(
                  "./views/dashboard/gcp/tabs/assets_explorer/ResourceList"
                ),
            },
            {
              name: "assets-auto-labelling",
              path: "/dashboard/gcp/explorer/auto-labelling",
              meta: {
                title: "Auto Labelling",
                breadcrumb: [
                  {
                    title: "Auto Labelling",
                    name: "Auto Labelling",
                    link: "/dashboard/gcp/explorer/auto-labelling",
                    description: "List of all your labels",
                  },
                ],
              },
              props: true,
              component: () =>
                import("./views/dashboard/gcp/tabs/labels/autoLabel"),
            },
          ],
          props: true,
          component: () => import("./views/dashboard/gcp/tabs/assets_explorer"),
        },
        {
          name: "cud-list",
          path: "/dashboard/gcp/cud",
          meta: {
            title: "CUD",
            breadcrumb: [
              {
                title: "Explorer",
                name: "Explorer",
                link: "/dashboard/gcp/explorer",
              },
              {
                title: "Committed Use Discounts",
                name: "committed-use-discounts",
                link: "/dashboard/gcp/explorer",
                description:
                  "List of your active commitments and committed use discounts",
              },
            ],
          },
          component: () => import("./views/dashboard/gcp/tabs/cud_list"),
        },
        {
          name: "cost-reports-tab",
          path: "/dashboard/gcp/reports",
          meta: {
            title: "Reports",
            breadcrumb: [
              {
                title: "Reports",
                name: "Reports",
                description:
                  "View detailed reports of your cloud expenditure using labels, services, and temporal filters. Explore visualization options and export to CSV or save in Custom views.",
                link: "/dashboard/gcp/reports",
                imageLink:
                  "https://ik.imagekit.io/economize/Content_Cost_Reports_vIoIJoElY.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074026219",
              },
            ],
          },
          component: () =>
            import("./views/dashboard/gcp/tabs/cost_reports_tab"),
        },
        {
          name: "labels",
          path: "/dashboard/gcp/labels/:tab?",
          meta: {
            title: "Labels",
            breadcrumb(route) {
              const tab = route.params.tab ?? "label-resources";
              return [
                {
                  isTab: true,
                  title: {
                    "label-resources": "Label Resources",
                    "manage-labels": "Manage Labels",
                    "auto-labelling": "Auto Labelling",
                  }[tab],
                  name: "Labels",
                  link: {
                    "label-resources": "/dashboard/gcp/labels/label-resources",
                    "manage-labels": "/dashboard/gcp/labels/manage-labels",
                    "auto-labelling": "/dashboard/gcp/labels/auto-labelling",
                  }[tab],
                  description:
                    "Assign custom labels to your resources for easier organization and comprehension. Manage specific label values and utilize the Auto Labeling feature to save time.",
                  imageLink:
                    "https://ik.imagekit.io/economize/Content_Labels_0qc8Ltds0.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074027417",
                },
              ];
            },
          },
          props: true,
          component: () => import("./views/dashboard/gcp/tabs/labels"),
        },
        {
          name: "dashboard",
          path: "/dashboard",
          meta: {
            title: "Dashboard",
          },
          component: () => import("./views/dashboard/gcp/org"),
        },
        {
          name: "cloud-cost-breakdown",
          path: "/dashboard/cloud-cost-breakdown",
          meta: {
            title: "Cost Breakdown",
          },
          component: () =>
            import("./views/dashboard/gcp/org/CloudCostBreakdown"),
        },
        {
          name: "anomaly-alerts",
          path: "/dashboard/anomaly-alerts",
          meta: {
            title: "Incidents",
          },
          component: () =>
            import("./views/dashboard/gcp/org/cost_anomalies_org_page"),
        },
        {
          name: "org-hierarchy",
          path: "/dashboard/org-hierarchy",
          meta: {
            title: "Organization Hierarchy",
          },
          component: () =>
            import("./views/dashboard/gcp/org/OrganizationHierarchy"),
        },
        {
          name: "cost-reports",
          path: "/dashboard/cost-reports",
          meta: {
            title: "Reports",
          },
          component: () =>
            import("./views/dashboard/gcp/org/cost_reports_org_page"),
        },
        {
          name: "unit-cost-metrics",
          path: "/dashboard/unit-cost-metrics",
          meta: {
            title: "Unit Cost Metrics",
          },
          component: () =>
            import("./views/dashboard/gcp/org/UnitCostMetrics.vue"),
        },
        {
          name: "unit-cost-metrics-cost-breakdown",
          path: "/dashboard/unit-cost-metrics/cost-breakdown",
          meta: {
            title: "Unit Cost Metrics",
          },
          component: () =>
            import(
              "./views/dashboard/gcp/components/billing/MultiLabelDrillDown"
            ),
        },

        {
          name: "get-started-gcp",
          path: "/gcp/get-started",
          meta: {
            title: "Get Started",
          },
          component: () => import("./views/pages/get-started/gcp"),
        },
        {
          name: "getAuthToken",
          path: "/getAuthToken",
          meta: {
            title: "Get Auth Token",
          },
          component: () => import("./views/pages/auth-token"),
        },
        {
          name: "settings",
          path: "/settings/:tab?",
          meta: {
            title: "Settings",
          },
          props: true,
          component: () => import("./views/pages/settings"),
        },
        // Slack App Integration
        {
          name: "slack-app",
          path: "/slackapp",
          meta: {
            title: "Slack App",
          },
          component: () => import("./views/pages/slackapp"),
        },

        // ================================================= AWS ==============================================
        {
          name: "aws-dashboard-nested",
          path: "/",
          redirect: "dashboard",
          meta: {
            title: "AWS Dashboard",
          },
          component: () => import("./layouts/AWSNestedMain"),
          children: [
            {
              // dashboard
              name: "aws-dashboard",
              path: "/dashboard/aws",
              meta: {
                title: "AWS Dashboard",
              },
              component: () => import("./views/dashboard/aws/org"),
            },
            {
              name: "anomaly-alerts-org-aws",
              path: "/dashboard/aws/anomaly-alerts",
              meta: {
                title: "Incidents",
              },
              component: () =>
                import("./views/dashboard/aws/org/cost_anomalies_org_page.vue"),
            },

            {
              name: "unit-cost-metrics-org-aws",
              path: "/dashboard/aws/unit-cost-metrics",
              meta: {
                title: "Unit Cost Metrics",
              },
              component: () =>
                import("./views/dashboard/aws/org/cost_metrics/index.vue"),
            },

            {
              name: "unit-cost-metrics-cost-breakdown-org-aws",
              path: "/dashboard/aws/unit-cost-metrics/cost-breakdown",
              meta: {
                title: "Unit Cost Metrics",
              },
              component: () =>
                import(
                  "./views/dashboard/aws/components/cost_metrics/MultiLabelDrillDown"
                ),
            },
            {
              name: "anomaly-alerts-aws",
              path: "/dashboard/aws/home/incidents",
              meta: {
                title: "Incidents",
                breadcrumb: [
                  {
                    title: "Incidents",
                    name: "Incidents",
                    description:
                      "Real-time anomaly detection on your cloud costs that helps prevent accidental cost spikes",
                    link: "/dashboard/aws/home/incidents",
                    imageLink:
                      "https://ik.imagekit.io/economize/Content_Anomaly_alerts_ClIUkCmdY.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074026475",
                  },
                ],
              },
              component: () =>
                import("./views/dashboard/aws/tabs/cost_anomalies_tab"),
            },

            {
              path: "/dashboard/aws/home",
              meta: {
                title: "Home",
                breadcrumb: [
                  {
                    title: "Home",
                    name: "Home",
                    description:
                      "A brief summary of the project's cloud costs and your monthly cloud spend on services.",
                    link: "/dashboard/aws/home",
                    imageLink:
                      "https://ik.imagekit.io/economize/Content_Home_r3UFH7uHk.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074028012",
                  },
                ],
              },

              component: () => import("./views/dashboard/aws"),
            },
            {
              name: "cost-reports-tab-aws",
              path: "/dashboard/aws/home/reports",
              meta: {
                title: "Reports",
                breadcrumb: [
                  {
                    title: "Reports",
                    name: "Reports",
                    description:
                      "View detailed reports of your cloud expenditure using labels, services, and temporal filters. Explore visualization options and export to CSV or save in Custom views.",
                    link: "/dashboard/aws/home/reports",
                    imageLink:
                      "https://ik.imagekit.io/economize/Content_Cost_Reports_vIoIJoElY.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074026219",
                  },
                ],
              },
              component: () =>
                import("./views/dashboard/aws/tabs/cost_reports_tab"),
            },
            // do you know understand about listRegions? https://aws.amazon.com/regions/

            {
              name: "recommendations-tab-aws",
              path: "/dashboard/aws/home/recommendations",
              meta: {
                title: "Recommendations",
                breadcrumb: [
                  {
                    title: "Recommendations",
                    name: "Recommendations",
                    description:
                      "Automatically get custom recommendations that are designed for your specific use case. Filter recommendations by category, and dismiss or act upon smart suggestions.",
                    link: "/dashboard/aws/home/recommendations",
                    imageLink:
                      "https://ik.imagekit.io/economize/Content_Recommendations_GXd1-II6Q.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1656074026716",
                  },
                ],
              },
              component: () =>
                import("./views/dashboard/aws/tabs/recommendations"),
            },
            {
              name: "cost-reports-org-aws",
              path: "/dashboard/aws/reports",
              meta: {
                title: "Reports",
              },
              component: () => import("./views/dashboard/aws/org/cost_reports"),
            },
            {
              name: "cost-breakdown-org-aws",
              path: "/dashboard/aws/cloud-cost-breakdown",
              meta: {
                title: "Cost Breakdown",
              },
              component: () =>
                import("./views/dashboard/aws/org/cost_breakdown"),
            },
          ],
        },
        {
          name: "get-started-aws",
          path: "/aws/get-started",
          meta: {
            title: "Get Started",
          },
          component: () => import("./views/pages/get-started/aws"),
        },
        {
          name: "setting-up-aws",
          path: "/aws/setting-up",
          meta: {
            title: "Setting up",
          },
          component: () => import("./views/pages/setting-up/aws"),
        },
      ],
    },
    {
      name: "onboard-gcp",
      path: "/gcp/onboard",
      meta: {
        title: "Onboarding",
      },
      props: true,
      component: () => import("./views/pages/onboard/gcp"),
    },
    {
      name: "onboard-aws",
      path: "/aws/onboard",
      meta: {
        title: "Onboarding",
      },
      component: () => import("./views/pages/onboard/aws"),
    },
    {
      name: "existing-onboard-aws",
      path: "/aws/existing-onboard",
      meta: {
        title: "Onboarding",
      },
      component: () => import("./views/pages/existing-onboard/aws"),
    },
    {
      name: "onboard-aws-old",
      path: "/aws/onboard/old",
      meta: {
        title: "Onboarding",
      },
      component: () => import("./views/pages/onboard/aws/old"),
    },
    // Join invited organization
    {
      path: "/invite",
      meta: {
        title: "Join Organization",
      },
      component: () => import("./views/auth/join-organization"),
    },
    {
      path: "/invite/redirect",
      meta: {
        title: "Validate Token",
      },
      component: () =>
        import("./views/auth/join-organization/validateToken.vue"),
    },
    // Public Pages
    {
      path: "/public",
      component: () => import("./layouts/Public"),
      redirect: "public/maintenance",
      children: [
        {
          path: "/public/maintenance",
          meta: {
            title: "Maintenance Mode",
          },
          component: () => import("./views/pages/maintenance"),
        },
        {
          path: "/public/ui-icons",
          meta: {
            title: "Ui Icons",
          },
          component: () => import("./views/icons"),
        },
      ],
    },
    {
      path: "/public/slackapp/redirect",
      meta: {
        title: "Redirecting",
      },
      beforeEnter(to, from, next) {
        window.location.href =
          to.query.state +
          window.location.search +
          "&redirect_uri=https://app.economize.cloud/public/slackapp/redirect";
      },
    },
    // System Pages
    {
      path: "/auth",
      component: () => import("./layouts/Auth"),
      redirect: "auth/login",
      children: [
        {
          path: "/auth/auth-upgrade",
          meta: {
            title: "Rest Password",
          },
          component: () => import("./views/auth/check_your_mail"),
        },
        {
          path: "/auth/merge-account",
          meta: {
            title: "Merge account",
          },
          component: () => import("./views/auth/merge_account"),
        },
        {
          name: "reset-password",
          path: "/auth/reset-password",
          meta: {
            title: "Reset Password",
          },
          component: () => import("./views/auth/reset-password"),
        },
        {
          path: "/auth/forgot-password",
          meta: {
            title: "Forgot Password",
          },
          component: () => import("./views/auth/forgot-password"),
        },
      ],
    },
    { path: "/auth/login", redirect: "/login" },
    { path: "/auth/register", redirect: "/register" },
    {
      path: "/",
      component: () => import("./layouts/Auth/Auth3"),
      children: [
        {
          path: "/login",
          meta: {
            title: "Sign In",
          },
          component: () => import("./views/login"),
        },

        {
          path: "/register",
          meta: {
            title: "Sign Up",
          },
          component: () => import("./views/auth/register"),
        },
      ],
    },
    {
      path: "/",
      meta: {
        authRequired: true,
        hidden: true,
      },
      component: () => import("./layouts/Welcome"),
      children: [
        {
          path: "/welcome",
          meta: {
            title: "Welcome",
          },
          component: () => import("./views/auth/welcome"),
        },
      ],
    },
    {
      path: "/auth",
      component: () => import("./layouts/Auth/Auth2"),
      redirect: "auth/login",
      children: [
        {
          path: "/auth/404",
          meta: {
            title: "Error 404",
          },
          component: () => import("./views/auth/404"),
        },
        {
          path: "/auth/500",
          meta: {
            title: "Error 500",
          },
          component: () => import("./views/auth/500"),
        },
        {
          name: "verify-email",
          path: "/auth/emailVerification",
          meta: {
            title: "Email verification",
          },
          component: () => import("./views/auth/verify-email/index"),
        },
        {
          path: "/auth/lockscreen",
          meta: {
            title: "Lockscreen",
          },
          component: () => import("./views/auth/lockscreen"),
        },
        {
          path: "/auth/manage",
          meta: {
            title: "Manage authentication",
          },
          component: () => import("./views/auth/manage"),
        },
      ],
    },

    // Redirect to 404
    {
      path: "*",
      redirect: "auth/404",
      hidden: true,
    },
  ],
});
const noMenuPages = [
  "get-started-gcp",
  "onboard-gcp",
  "setting-up-gcp",
  "dashboard",
  "cloud-cost-breakdown",
  "cost-reports",
  "unit-cost-metrics",
  "unit-cost-metrics-cost-breakdown",
  "anomaly-alerts",
  "org-hierarchy",
  "custom-views",
  // AWS
  "get-started-aws",
  "setting-up-aws",
  "onboard-aws",
  "onboard-aws-old",
  "existing-onboard-aws",
  "aws-dashboard",
  "cost-breakdown-org-aws",
  "anomaly-alerts-org-aws",
  "cost-reports-org-aws",
  "unit-cost-metrics-org-aws",
  "unit-cost-metrics-cost-breakdown-org-aws",
];

const allowedTrialPages = [
  "dashboard",
  "settings",
  // GCP
  "get-started-gcp",
  "onboard-gcp",
  "setting-up-gcp",
  // AWS
  "get-started-aws",
  "setting-up-aws",
  "onboard-aws",
  "onboard-aws-old",
  "existing-onboard-aws",
];

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    const nprogress = new NProgress({ parent: "body" });

    let disallowedRoutes = [];

    // FEATURE
    if (store.state.user.maintenanceMode) {
      if (to.path !== "/public/maintenance") {
        next({ path: "/public/maintenance" });
      }
      return;
    } else {
      if (!store.state.user.features.variation) {
        setTimeout(() => router.push("/"), 200);
      }
      disallowedRoutes =
        store.state.user.features.variation(
          "frontend-features"
        ).disallowedRoutes;
      for (let i = 0; i < disallowedRoutes.length; i++) {
        if (to.path === disallowedRoutes[i]) {
          return;
        }
      }
    }

    // RESET PASSWORD WHEN NEEDED
    if (store.state.user.passwordReset) {
      next("/auth/auth-upgrade");
    }
    const selectedMode = localStorage.getItem("selectedCloudProvider") ?? "GCP";

    // RESET PASSWORD WHEN NEEDED
    if (localStorage.getItem("isLoggedIn") === "true") {
      nprogress.start();
      await store.dispatch("user/VALIDATE_PROJECT");
    } else {
      next("/login");
    }

    if (store.state.user.mergeAccount) {
      next("/auth/merge-account");
    }

    // RESET PASSWORD WHEN NEEDED
    if (
      to.name !== "dashboard" &&
      store.state.user.trial_expired &&
      store.state.user.projects.length !== 0
    ) {
      store.state.settings.menuLayoutType = "nomenu";
      if (!allowedTrialPages.includes(to.name)) {
        next("/dashboard");
      }
    }

    let awsProject = store.state.user.projects.filter(
      (project) => project.type === "AWS"
    );

    const gcpProject = store.state.user.projects.filter(
      (project) => project.type === "GCP"
    );

    // RESET PASSWORD WHEN NEEDED
    if (!store.state.user.authorized) {
      // next({
      //   path: '/auth/login',
      //   query: { redirect: to.fullPath },
      // })
    } else if (
      from.path !== "/welcome" &&
      to.path !== "/welcome" &&
      common.isEmptyObject(store.state.user.selectedProject) &&
      selectedMode === "GCP"
    ) {
      if (localStorage.getItem("isLoggedIn") === "true") {
        next("/welcome");
      }
    } else if (
      selectedMode === "GCP" &&
      (to.path.search("/dashboard/gcp") !== -1 ||
        to.path.search("/dashboard") !== -1) &&
      store.state.user.selectedProject.dataset !== "" &&
      store.state.user.selectedProject.dataset.split(".").length < 3
    ) {
      next("/gcp/setting-up");
    } else if (
      from.path !== "/aws/get-started" &&
      to.path !== "/aws/get-started" &&
      common.isEmptyObject(store.state.user.selectedAccount) &&
      selectedMode === "AWS"
    ) {
      if (localStorage.getItem("isLoggedIn") === "true") {
        next("/aws/get-started");
      }
      // path !== gcp path and provider is AWS then push aws dashboard
      // (or)
      // when gcp project is empty and aws project is available
      // and provider is GCP then push aws dashboard
    } else if (
      to.path !== "/dashboard/aws" &&
      from.path !== "/dashboard/aws" &&
      (((to.path.includes("/gcp") || to.path === "/dashboard") &&
        selectedMode === "AWS" &&
        awsProject.length !== 0) ||
        (awsProject.length !== 0 &&
          gcpProject.length === 0 &&
          selectedMode === "GCP"))
    ) {
      localStorage.setItem("selectedCloudProvider", "AWS");
      store.commit("user/SET_STATE", {
        selectedCloudProvider: "AWS",
      });
      next("/dashboard/aws");
      // path !== aws path and provider is AWS then push aws dashboard
      // (or)
      // when aws project is empty and gcp project is available
      // and provider is AWS then push gcp dashboard
    } else if (
      to.path !== "/dashboard" &&
      from.path !== "/dashboard" &&
      ((to.path.includes("/aws") &&
        selectedMode === "GCP" &&
        gcpProject.length !== 0) ||
        (gcpProject.length !== 0 &&
          awsProject.length === 0 &&
          selectedMode === "AWS"))
    ) {
      localStorage.setItem("selectedCloudProvider", "GCP");
      store.commit("user/SET_STATE", {
        selectedCloudProvider: "GCP",
      });
      next("/dashboard");
    } else {
      next();
    }
  } else {
    if (!store.state.user.maintenanceMode) {
      if (to.path === "/public/maintenance") {
        next({ path: "/" });
        return;
      }
    }
    next();
  }

  if (noMenuPages.includes(to.name) || getMenu().length === 0) {
    store.state.settings.menuLayoutType = "nomenu";
  } else {
    store.state.settings.menuLayoutType = "left";
  }

  if (store.state.user.trial_expired) {
    store.state.settings.menuLayoutType = "nomenu";
  }
});

export default router;
