import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import common from "@/views/dashboard/common";
import * as jwt from "@/services/jwt";
import auth0 from "@/services/auth0";
import store from "@/store";
import LogRocket from "logrocket";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";
import { frontendFeatures } from "@/WtConstants";
import NProgress from "vue-nprogress";

import _default from "vuex";
import isEmpty from "lodash/isEmpty";

import { signInWithCustomToken } from "firebase/auth";
import { FirebaseAuth, firebaseDatabase } from "@/services/firebase";
import { doc, getDoc } from "firebase/firestore";

const mode = process.env.VUE_APP_MODE;

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
  auth0: {
    currentAccount: auth0.renewTokens,
    getProfile: auth0.getUserInfo,
    logout: auth0.logOut,
    isExpired: auth0.isExpired,
  },
};

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    id: "",
    name: "",
    role: "",
    email: "",
    avatar: "",
    recordingURL: "",
    isErrorLogin: false,
    projects: [],
    selectedCloudProvider: "GCP",
    selectedProject: {},
    selectedAccount: {},
    mergeAccount: false,
    passwordReset: false,
    dataset: "",
    detailed_dataset: "",
    defaultActiveTab: "",
    activeBreadcrumbLink: "",
    isEnabledWeeklyTraining: false,
    has_multiple_auth_providers: false,
    mfaRequired: false,
    mfaResolver: null,
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    loading: false,
    pricing_plan: "Free",
    maintenanceMode: false,
    whiteLabelUser: process.env.VUE_APP_WL_USER || "economize",
    organization_id: "",
    trial_expired: false,
    features: {},
    organization: [],
    keys: {},
    tabConfig: {},
    billed_custom: false,
    is_sso: false,
    tenant_domain: "",
    total_recommendations: 0,
    total_org_cost: "0",
    isSubscribed: false,
  },
  mutations: {
    SET_BREADCRUMB(state, payload) {
      window.localStorage.setItem(
        `app.breadcrumbs.${payload.title}`,
        payload.value
      );
      state[payload.title] = payload.value;
    },
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      });
    },
  },
  actions: {
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload;
      commit("SET_STATE", {
        loading: true,
      });

      const login = mapAuthProviders[rootState.settings.authProvider].login;
      login(email, password).then((success) => {
        if (success) {
          Vue.prototype.$notification.config({
            duration: 2.5,
          });
          Vue.prototype.$notification.success({
            message: "Logged In",
            description: "You have successfully logged in.",
          });
          commit("SET_STATE", {
            loading: true,
            authorized: true,
          });
        } else {
          commit("SET_STATE", {
            loading: false,
          });
        }
      });
    },
    UPDATE_PROJECT_ORG_DATA(
      { commit },
      { selectedProject, projects, pricing_plan }
    ) {
      commit("SET_STATE", {
        selectedProject: selectedProject,
        projects: projects,
        pricing_plan: pricing_plan,
      });
    },
    async REGISTER_AUTH0({ commit, dispatch, rootState }, { payload }) {
      const nprogress = new NProgress({ parent: "body" });

      const { email, token, orgName, is_user_exist } = payload;
      commit("SET_STATE", {
        loading: true,
      });
      let url, data, message, description, redirectRoute;
      // TODO: Move axios call outside to register, call register on API success.
      if (email && orgName && token && !is_user_exist) {
        // if email, orgName & token is returned in payload - join org flow
        url = "/meta/users/new/org";
        data = {
          userId: this.state.user.id,
          email: email,
          name: this.state.user.name,
          picture_url: this.state.user.avatar,
          organization_name: orgName,
          token: token,
        };
        message = "Joined successfully";
        description = "You have successfully joined the organization.";
        redirectRoute = "/dashboard";
      } else if (token && is_user_exist) {
        // else join the org
        url = "/meta/org/join";
        data = {
          token: token,
        };
        message = "Succesful Joined";
        description = "You have successfully joined.";
        redirectRoute = "/dashboard";
      } else {
        const newsletter = window.localStorage.getItem("newsletter");
        store.commit("user/SET_STATE", { isSubscribed: newsletter });

        if (newsletter) {
          const res = fetch(
            "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjIwNTY5MDYzMTA0MzA1MjZjNTUzMyI_3D_pc",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ orgID: this.state.user.email }),
            }
          ).catch((err) => {
            console.log("error", err);
          });
        }

        // else add new user
        url = "/meta/users/new";
        data = {
          userId: this.state.user.id,
          email: this.state.user.email,
          organization_name: payload.orgName,
          name: this.state.user.name,
          picture_url: this.state.user.avatar,
        };
        message = "Succesful Registered";
        description = "You have successfully registered.";
        redirectRoute = "/gcp/get-started";
      }

      nprogress.start();

      await common.axios
        .post(common.apiURL + url, data)
        .then(() => {
          localStorage.setItem("isLoggedIn", "true");
          Vue.prototype.$notification.success({
            message: message,
            description: description,
          });

          if (!payload.hasMultipleAuthProviders || !payload.hasPassword) {
            if (url === "/meta/org/join") {
              window.location.reload();
            }
            router.push(redirectRoute);
          }
        })
        .catch(() => {
          if (url === "/meta/org/join") {
            Vue.prototype.$notification.error({
              message: "Unable to join the organization",
            });
            window.location.reload();
          }
          if (!is_user_exist) {
            url = "/meta/users/new";
            data = {
              userId: this.state.user.id,
              email: this.state.user.email,
              organization_name: payload.orgName,
              name: this.state.user.name,
              picture_url: this.state.user.avatar,
            };
            message = "Succesful Registered";
            description = "You have successfully registered.";
            redirectRoute = "/gcp/get-started";

            common.axios.post(common.apiURL + url, data).then(() => {
              localStorage.setItem("isLoggedIn", "true");
              Vue.prototype.$notification.success({
                message: message,
                description: description,
              });

              if (!payload.hasMultipleAuthProviders || !payload.hasPassword) {
                router.push(redirectRoute);
              }
            });
          } else {
            localStorage.setItem("isLoggedIn", "true");
            router.push("/dashboard");
          }
        })
        .finally(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("orgName");
          nprogress.done();
        });
    },
    VALIDATE_PROJECT({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const selectedMode =
          localStorage.getItem("selectedCloudProvider") ?? "GCP";

        if (
          (selectedMode === "GCP" &&
            common.isEmptyObject(this.state.user.selectedProject)) ||
          (selectedMode === "AWS" &&
            common.isEmptyObject(this.state.user.selectedAccount))
        ) {
          dispatch("REFRESH_PROJECT").then(resolve).catch(reject);
        } else {
          resolve();
        }
      });
    },
    REFRESH_PROJECT({ commit }) {
      return new Promise(async (resolve, reject) => {
        const token = auth0.accessToken;
        if (token === null || token === "") {
          await auth0.renewTokens();
        }
        const frontendConfigs = this.state.user.features.variation(
          "frontend-features",
          frontendFeatures
        );

        if (
          this.state.user.authorized &&
          process.env.VUE_APP_MODE === "prod" &&
          !this.state.user.email.includes("@economize.cloud")
        ) {
          const { requestSanitizer, responseSanitizer } =
            LogrocketFuzzySanitizer.setup([
              "Authorization",
              "refresh_token",
              "access_token",
              "id_token",
              "access_key",
              "access_sercet",
              "datadogToken",
              "project",
              "vault_credentials",
            ]);
          const modifiedRequestSanitizer = (request) => {
            request.headers["Authorization"] = "*";
            return requestSanitizer(request);
          };
          const modifiedResponseSanitizer = (response1) => {
            response1.headers["Authorization"] = "*";
            return responseSanitizer(response1);
          };
          LogRocket.init("gnl3m7/economize", {
            network: {
              requestSanitizer: modifiedRequestSanitizer,
              responseSanitizer: modifiedResponseSanitizer,
            },
          });

          LogRocket.identify(this.state.user.id, {
            name: this.state.user.name,
            email: this.state.user.email,
          });

          LogRocket.getSessionURL(function (sessionURL) {
            commit("SET_STATE", {
              recordingURL: sessionURL,
            });
          });
        }

        await common.axios
          .get(common.apiURL + "/meta/users/get")
          .then(async (response) => {
            const responseUserId = response.data.userId;
            let projects = [];
            let GCPProjects = [];
            let AWSProjects = [];

            const OrgWithoutTestData = response.data.organization.filter(
              (e) =>
                e.organization_id !== frontendConfigs.test_data_org_id[mode]
            );
            let allowed = OrgWithoutTestData.every(
              (e) => e.trial_expired !== false
            );
            if (isEmpty(OrgWithoutTestData)) {
              allowed = false;
            }
            const organization_data = response.data.organization.map((arr) => {
              if (
                arr.organization_id === frontendConfigs.test_data_org_id[mode]
              ) {
                return { ...arr, trial_expired: allowed };
              }
              return arr;
            });

            let localStorageOrgID = localStorage.getItem("organization_id");

            if (localStorageOrgID && !common.isEmptyObject(localStorageOrgID)) {
              const isExistorganization = organization_data.find(
                (x) => x.organization_id === localStorageOrgID
              );

              if (!isExistorganization) {
                localStorageOrgID = undefined;
              }
            }

            if (localStorageOrgID && !common.isEmptyObject(localStorageOrgID)) {
              const organization = organization_data.find(
                (x) => x.organization_id === localStorageOrgID
              );

              // GCP
              GCPProjects = organization.gcpProjects.map((p) => ({
                ...p,
                type: "GCP",
              }));

              AWSProjects = organization.awsProjects.map((p) => ({
                ...p,
                type: "AWS",
              }));

              projects = [].concat(GCPProjects, AWSProjects);

              commit("SET_STATE", {
                organization: organization_data,
                organization_id: localStorageOrgID,
              });
            } else {
              // GCP

              GCPProjects = organization_data[0].gcpProjects.map((p) => ({
                ...p,
                type: "GCP",
              }));

              AWSProjects = organization_data[0].awsProjects.map((p) => ({
                ...p,
                type: "AWS",
              }));

              projects = [].concat(GCPProjects, AWSProjects);

              commit("SET_STATE", {
                organization: organization_data,
                organization_id: organization_data[0].organization_id,
              });

              localStorageOrgID = organization_data[0].organization_id;
              localStorage.setItem(
                "organization_id",
                organization_data[0].organization_id
              );
            }

            if (GCPProjects.length === 0) {
              localStorage.setItem("selectedProject", "{}");
            }
            if (AWSProjects.length === 0) {
              localStorage.setItem("selectedAccount", "{}");
            }

            const selectedData = organization_data.filter(
              (arr) => arr.organization_id === localStorageOrgID
            )[0];

            // =============== AWS ================
            let accountToSelect;
            let localStorageAccountToSelect =
              localStorage.getItem("selectedAccount");

            localStorageAccountToSelect =
              localStorageAccountToSelect === "undefined"
                ? undefined
                : localStorageAccountToSelect;

            if (
              localStorageAccountToSelect &&
              !common.isEmptyObject(localStorageAccountToSelect)
            ) {
              const isExistProject = AWSProjects.find(
                (x) =>
                  x.project_id ===
                  JSON.parse(localStorageAccountToSelect).project_id
              );

              if (!isExistProject) {
                localStorageAccountToSelect = undefined;
              }
            }

            if (
              localStorageAccountToSelect &&
              !common.isEmptyObject(localStorageAccountToSelect)
            ) {
              accountToSelect = JSON.parse(localStorageAccountToSelect);
            } else {
              accountToSelect = AWSProjects[0] ?? {};
              localStorage.setItem(
                "selectedAccount",
                JSON.stringify(accountToSelect)
              );
            }

            // =============== GCP ================
            let projectToSelect;
            let localStorageProjectToSelect =
              localStorage.getItem("selectedProject");

            localStorageProjectToSelect =
              localStorageProjectToSelect === "undefined"
                ? undefined
                : localStorageProjectToSelect;
            let newOnBoardedProject = localStorage.getItem("addedProject");
            localStorage.removeItem("addedProject");

            if (
              localStorageProjectToSelect &&
              !common.isEmptyObject(localStorageProjectToSelect) &&
              !newOnBoardedProject
            ) {
              const isExistProject = GCPProjects.find(
                (x) =>
                  x.project_id ===
                  JSON.parse(localStorageProjectToSelect).project_id
              );

              if (!isExistProject) {
                localStorageProjectToSelect = undefined;
              }
            }

            if (
              localStorageProjectToSelect &&
              !common.isEmptyObject(localStorageProjectToSelect) &&
              !newOnBoardedProject
            ) {
              projectToSelect = JSON.parse(localStorageProjectToSelect);
            } else {
              projectToSelect = GCPProjects[0] || {};
              if (newOnBoardedProject != "") {
                const isExistProject = GCPProjects.find(
                  (x) => x.project_id == newOnBoardedProject
                );
                projectToSelect = isExistProject || GCPProjects[0] || {};
              } else {
                projectToSelect = GCPProjects[0] || {};
              }
              if (this.state.user.email === "guest@economize.cloud") {
                projectToSelect =
                  GCPProjects.filter(
                    (p) => p.project_id === "economize-cloud"
                  )[0] || {};
              }
              localStorage.setItem(
                "selectedProject",
                JSON.stringify(projectToSelect)
              );
            }

            if (responseUserId === this.state.user.id) {
              let pricing_plan;
              if (localStorage.getItem("organization_id")) {
                let selectedOrganization = response.data.organization.find(
                  (x) =>
                    x.organization_id ===
                    localStorage.getItem("organization_id")
                );
                if (selectedOrganization.billed_custom) {
                  pricing_plan = "Custom Plan";
                } else {
                  pricing_plan = selectedOrganization.pricing_plan;
                }
              }

              commit("SET_STATE", {
                selectedProject: projectToSelect,
                selectedAccount: accountToSelect,
                projects: projects,
                pricing_plan: pricing_plan,
                trial_expired: selectedData.trial_expired,
                is_sso: selectedData.is_sso,
                tenant_domain: selectedData.tenant_domain ?? "",
                selectedCloudProvider:
                  localStorage.getItem("selectedCloudProvider") ?? "GCP",
              });

              if (
                Object.keys(accountToSelect).length !== 0 &&
                this.state.user.selectedCloudProvider === "AWS"
              ) {
                await common.axios
                  .get(common.apiURL + "/aws/load_partition_date", {
                    params: {
                      project_id: accountToSelect.project_id,
                    },
                  })
                  .catch((err) => {
                    if (mode === "dev" || mode === "stg") {
                      console.log(err);
                    }
                  });
              }

              common.axios
                .get(common.apiURL + "/meta/firebase/token")
                .then((res) => {
                  signInWithCustomToken(FirebaseAuth, res.data).catch((err) => {
                    console.log(err);
                  });
                })
                .catch((err) => console.log(err));

              resolve();

              const appDataRef = doc(firebaseDatabase, "appData", "tabs");

              getDoc(appDataRef)
                .then((doc) => {
                  let tabConfig = doc.data();
                  tabConfig =
                    tabConfig[store.state.user.organization_id] ||
                    tabConfig["default"];
                  store.commit("user/SET_STATE", {
                    tabConfig,
                  });
                })
                .catch(reject);
            } else {
              reject(
                new Error(
                  "UserId mismatch between request and response during get user"
                )
              );
            }
          })
          .catch((err) => {
            if (mode === "dev" || mode === "stg") {
              console.log(err);
            }

            // if (err.response.status === 401) {
            //   router.push("/login");
            // }

            const nprogress = new NProgress({ parent: "body" });
            nprogress.done();
            commit("SET_STATE", {
              isErrorLogin: true,
            });
            reject(new Error("Get user failed"));
          });
      });
    },
    LOGOUT({ commit, rootState }) {
      auth0.logOut().then(() => {
        commit("SET_STATE", {
          id: "",
          name: "",
          role: "",
          email: "",
          avatar: "",
          projects: [],
          selectedProject: {},
          dataset: "",
          detailed_dataset: "",
          isEnabledWeeklyTraining: false,
          authorized: false,
          loading: false,
          mfaRequired: false,
          mfaResolver: null,
          organization_id: "",
        });
        localStorage.removeItem("selectedProject");
        localStorage.removeItem("organization_id");
      });
    },
    FORGET_PASSWORD({ commit, rootState }, { payload }) {
      const { email } = payload;
      commit("SET_STATE", {
        loading: true,
      });
      const forgetPassword =
        mapAuthProviders[rootState.settings.authProvider].forgetPassword;
      forgetPassword(email).then((success) => {
        if (success) {
          commit("SET_STATE", {
            loading: false,
          });
          router.push("/login");
        }
        if (!success) {
          commit("SET_STATE", {
            loading: false,
          });
        }
      });
    },
    RESET_PASSWORD({ commit, rootState }, { payload, oobCode }) {
      const { password } = payload;
      var code = oobCode;
      if (code !== null) {
        commit("SET_STATE", {
          loading: true,
        });
      } else {
        Vue.prototype.$notification.error({
          message: "Unauthorized",
          description:
            "No authorization code present to perform this operation.",
        });
      }
      const resetPassword =
        mapAuthProviders[rootState.settings.authProvider].resetPassword;
      resetPassword(code, password).then((success) => {
        if (success) {
          commit("SET_STATE", {
            loading: false,
          });
        }
        if (!success) {
          commit("SET_STATE", {
            loading: false,
          });
        }
      });
    },
  },
  getters: {
    user: (state) => state,
  },
};
