<script>
import Chart from "chart.js/auto";
import { LineController } from "chart.js";
import { Line, mixins } from "vue-chartjs";
import common from "@/views/dashboard/common";
const { reactiveProp } = mixins;
import "chartjs-plugin-style";

const color = common.lightChartColors;

// Line Shadow
class lineWithShadow extends LineController {
  draw() {
    const ctx = this.chart.ctx;
    ctx.shadowColor = "rgba(39, 127, 254, 0.3)";
    ctx.shadowBlur = 5;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 5;
    super.draw();
  }
}

lineWithShadow.id = "lineWithShadow";
lineWithShadow.defaults = LineController.defaults;
Chart.register(lineWithShadow);

export default {
  extends: Line,
  mixins: [reactiveProp],
  components: {
    LineController,
    Chart,
    Line,
    mixins,
  },
  props: {
    chartData: Object,
    tooltipCallbackFunc: {
      type: Object,
      default: null,
    },
    yAxesCallbackFunc: {
      type: Function,
      default: (val) => val,
    },
    xAxesCallbackFunc: {
      type: Function,
      default: function (value, index) {
        return this.getLabelForValue(value);
      },
    },
    stacked: {
      type: [Boolean, String],
      default: false,
    },
    legendDisplay: {
      type: [Boolean, String],
      default: false,
    },
    labelsDisplay: {
      type: [Boolean, String],
      default: false,
    },
    chart: {
      type: String,
      default: null,
    },
    chartTitle: {
      type: String,
      default: null,
    },
    tooltipBodyAlign: {
      type: String,
      default: null,
    },
    xAxisTitleText: {
      type: String,
      default: "Time",
    },
    yAxisTitleText: {
      type: String,
      default: "Cost",
    },
    chartTitleDisplay: {
      type: Boolean,
      default: false,
    },
    onClickLegendIncludeData: {
      type: Boolean,
      default: false,
    },
    xAxisTitleDisplay: {
      type: Boolean,
      default: false,
    },
    yAxisTitleDisplay: {
      type: Boolean,
      default: false,
    },
    yAxisTicksStepSize: {
      type: Number,
      default: undefined,
    },
    yAxisTicksMax: {
      type: Number,
      default: undefined,
    },
    tooltipPadding: {
      type: Number,
      default: 15,
    },
    tooltipCornerRadius: {
      type: Number,
      default: 20,
    },
    chartBottomLayout: {
      type: Number,
      default: 30,
    },
  },
  methods: {
    renderChartFunc() {
      // Gradient Chart
      let ctx = this.$refs.canvas.getContext("2d");
      let gradientChart = ctx.createLinearGradient(0, 0, 0, 150);
      gradientChart.addColorStop(0, "rgba(218, 221, 249,0.45)");
      gradientChart.addColorStop(1, "rgba(242, 251, 253,0.35)");

      if (this.chart === "single") {
        this.chartData.datasets = this.chartData.datasets.map((item) => {
          return {
            ...item,
            borderColor: "rgb(39, 127, 254)",
            fill: true,
            pointStyle: "circle",
            backgroundColor: gradientChart,
            pointHoverRadius: 7,
            pointBackgroundColor: "rgb(39, 127, 254)",
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointBorderColor: "rgb(39, 127, 254)",
            pointBorderWidth: 1,
            pointHoverBorderWidth: 4,
          };
        });
      } else if (this.chart === "multi") {
        this.chartData.datasets = this.chartData.datasets.map((item, index) => {
          return {
            ...item,
            fill: true,
            pointStyle: "circle",
            backgroundColor: gradientChart,
            borderColor: color[index % color.length],
            pointBackgroundColor: color[index % color.length],
            barThickness: 50,
            pointHoverRadius: 7,
            pointBorderWidth: 1,
            pointHoverBorderWidth: 4,
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
          };
        });
      }

      // Annotation Line on Hover
      const annotationLine = {
        id: "annotationLine",
        beforeDraw: (chart) => {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            var ctx = chart.ctx;
            var activePoint = chart.tooltip._active[0];
            var x = activePoint.element.x;
            var topY = chart.chartArea.top;
            var bottomY = chart.chartArea.bottom;

            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = "rgba(54, 54, 54, 0.1)";
            ctx.stroke();
            ctx.restore();
          }
        },
      };

      // Configuration for Line Charts
      const options = {
        type: "lineWithShadow", //line shadow type
        data: this.chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 20,
          elements: {
            line: {
              tension: 0,
            },
            point: {
              display: true,
              backgroundColor: "rgb(252, 152, 3)",
              radius: 0,
              hitRadius: 5,
              hoverRadius: 7,
            },
          },
          pointStyle: "circle",
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: this.chartBottomLayout,
            },
          },
          hover: { intersect: false },
          scales: {
            x: {
              stacked: this.stacked,
              display: true,
              color: "rgb(134, 147, 157)",
              borderDash: [2, 2],
              grid: {
                zeroLineColor: "rgba(217, 217, 217,0.25)",
                color: "rgba(217, 217, 217,0.6)",
                borderDash: [2, 2],
                drawTicks: false,
              },
              ticks: {
                beginAtZero: true,

                font: {
                  size: 12,
                  family: "Inter",
                  color: "rgb(134, 147, 157)",
                },
                padding: 10,
                fontFamily: "Inter",
                maxTicksLimit: 15,
                autoSkip: true,
                maxRotation: 0,
                minRotation: 0,
                callback: this.xAxesCallbackFunc,
              },
              pointLabels: {
                fontFamily: "Inter",
              },
              title: {
                display: this.xAxisTitleDisplay,
                text: this.xAxisTitleText,
              },
            },
            y: {
              stacked: this.stacked,
              display: true,
              color: "rgb(134, 147, 157)",
              grid: {
                zeroLineColor: "rgba(238, 238, 238,0.6)",
                drawTicks: false,
                color: "rgba(238, 238, 238,0.6)",
              },
              ticks: {
                beginAtZero: true,
                callback: this.yAxesCallbackFunc,
                font: {
                  size: 12,
                  family: "Inter",
                  color: "rgb(134, 147, 157)",
                },
                padding: 10,
                maxTicksLimit: 8,
                stepSize: this.yAxisTicksStepSize,
              },
              max: this.yAxisTicksMax,
              pointLabels: {
                fontFamily: "Inter",
              },
              title: {
                display: this.yAxisTitleDisplay,
                text: this.yAxisTitleText,
              },
            },
          },
          animation: {
            x: {
              duration: 1500,
              from: 0,
            },
            y: {
              duration: 1500,
              from: 500,
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: this.legendDisplay,
              labels: {
                boxWidth: 10,
                boxWidth: 10,
                display: true,
                usePointStyle: true,
                pointStyle: "circle",
                padding: 10,
                font: {
                  size: 12,
                  family: "Inter",
                  color: "rgb(134, 147, 157)",
                },
                generateLabels: (chart) => {
                  let visibility = [];
                  for (let i = 0; i < chart.data.datasets.length; i++) {
                    if (chart.isDatasetVisible(i)) {
                      visibility.push("#000000");
                    } else {
                      visibility.push("rgba(102,102,102,0.5");
                    }
                  }
                  return chart.data.datasets.map((labelText, index) => ({
                    text: labelText.label,
                    fillStyle: labelText.borderColor,
                    strokeStyle: labelText.borderColor,
                    fontColor: visibility[index],
                  }));
                },
              },
              position: "bottom",
              onClick: this.onClickLegendIncludeData
                ? function (e, legendItem, legend) {
                    let index;
                    legend.chart.data.datasets.map((text, i) => {
                      if (text.label === legendItem.text) {
                        index = i;
                      }
                    });
                    let ci = this.chart;
                    let alreadyHidden =
                      ci.getDatasetMeta(index).hidden === null
                        ? false
                        : ci.getDatasetMeta(index).hidden;
                    let anyOthersAlreadyHidden = false;
                    let allOthersHidden = true;

                    ci.data.datasets.forEach(function (e, i) {
                      let meta = ci.getDatasetMeta(i);

                      if (i !== index) {
                        if (meta.hidden) {
                          anyOthersAlreadyHidden = true;
                        } else {
                          allOthersHidden = false;
                        }
                      }
                    });

                    if (alreadyHidden) {
                      ci.getDatasetMeta(index).hidden = null;
                    } else {
                      ci.data.datasets.forEach(function (e, i) {
                        var meta = ci.getDatasetMeta(i);

                        if (i !== index) {
                          if (anyOthersAlreadyHidden && !allOthersHidden) {
                            meta.hidden = true;
                          } else {
                            meta.hidden =
                              meta.hidden === null ? !meta.hidden : null;
                          }
                        } else {
                          meta.hidden = null;
                        }
                      });
                    }

                    ci.update();
                  }
                : function (e, legendItem, legend) {
                    let index;
                    legend.chart.data.datasets.map((text, i) => {
                      if (text.label === legendItem.text) {
                        index = i;
                      }
                    });
                    const ci = legend.chart;
                    if (ci.isDatasetVisible(index)) {
                      ci.hide(index);
                      legendItem.hidden = true;
                    } else {
                      ci.show(index);
                      legendItem.hidden = false;
                    }
                  },
            },
            tooltip: {
              itemSort: function (a, b) {
                return b.raw - a.raw;
              },
              mode: "index",
              intersect: false,
              position: "average",
              backgroundColor: "rgba(0,0,0,0.7)",
              caretSize: 10,
              caretPadding: 10,
              cornerRadius: this.tooltipCornerRadius,
              titleFontFamily: "Inter",
              bodyFontFamily: "Inter",
              padding: this.tooltipPadding,
              usePointStyle: false,
              titleColor: "#fff",
              callbacks: this.tooltipCallbackFunc,
              borderColor: "rgba(54,54,54,0.20)",
              borderWidth: 2.5,
              bodyAlign: this.tooltipBodyAlign,
            },
            title: {
              display: this.chartTitleDisplay,
              text: this.chartTitle,
            },
          },
        },
        plugins: [annotationLine],
      };

      new Chart(ctx, options);
    },
  },

  mounted() {
    Chart.defaults.font.family = "Inter";
    Chart.defaults.font.weight = "500";
    this.renderChartFunc();
  },
};
</script>
