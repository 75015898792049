import "./index.css";
import "ant-design-vue/lib/style/index.less"; // antd core styles
import "./components/system/antd/themes/default.less"; // default theme antd components
// import "./components/system/antd/themes/dark.less"; // dark theme antd components
import "./global.scss"; // app & third-party component styles

import Vue from "vue";
import VuePageTitle from "vue-page-title";
import NProgress from "vue-nprogress";
import FormWizard from "vue-form-wizard";
import "prismjs";
import "prismjs/themes/prism.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./localization";
import auth0 from "./services/auth0";
import "./antd";

// base-button configuration
// import './base-components';
import VueClipboard from "vue-clipboard2";
// import VuePusher from 'vue-pusher'
// import Pusher from "pusher-js";
import * as LDClient from "launchdarkly-js-client-sdk";
import Loading from "@/views/dashboard/gcp/components/Loading";
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import BaseTable from "@/components/BaseTable";
import BaseText from "@/components/BaseText";
import BaseCard from "@/components/BaseCard";
import BaseLine from "@/components/BaseLine";
import BaseBar from "@/components/BaseBar";
import BasePie from "@/components/BasePie";
import BaseAlert from "@/components/BaseAlert";
import BaseTooltip from "@/components/BaseTooltip";
import BaseProgress from "@/components/BaseProgress";
import BaseDivider from "@/components/BaseDivider";
import BasePopupConfirm from "@/components/BasePopupConfirm";
import BaseDrawer from "@/components/BaseDrawer";
import BaseBox from "@/components/BaseBox";
import BaseWaterFall from "@/components/BaseWaterFall";
import BaseDatePicker from "@/components/BaseDatePicker";
import BaseMultiSelect from "@/components/BaseMultiSelect";
import BaseErrorMessage from "@/components/BaseErrorMessage";
import BaseAvatar from "@/components/BaseAvatar";

// Vue.use(VuePusher, {
//   api_key: appKey,
//   options: pusherOpts,
// })
Vue.use(FormWizard);
Vue.use(VueClipboard);
Vue.component("a-loader", Loading);
Vue.component("base-divider", BaseDivider);
Vue.component("base-progress", BaseProgress);
Vue.component("base-tooltip", BaseTooltip);
Vue.component("base-alert", BaseAlert);
Vue.component("base-input", BaseInput);
Vue.component("base-line", BaseLine);
Vue.component("base-bar", BaseBar);
Vue.component("base-pie", BasePie);
Vue.component("base-button", BaseButton);
Vue.component("base-table", BaseTable);
Vue.component("base-text", BaseText);
Vue.component("base-card", BaseCard);
Vue.component("base-popconfirm", BasePopupConfirm);
Vue.component("base-drawer", BaseDrawer);
Vue.component("base-box", BaseBox);
Vue.component("base-date-picker", BaseDatePicker);
Vue.component("base-multi-select", BaseMultiSelect);
Vue.component("base-error-message", BaseErrorMessage);
Vue.component("base-water-fall", BaseWaterFall);
Vue.component("base-avatar", BaseAvatar);

Vue.use(NProgress);
Vue.use(VuePageTitle, {
  suffix: "• Economize",
  router,
});

/**
 * App Init doc
 * The app initializes in the following sequence
 * 1. Launchdarkly token is fetched from env and is inited
 * 2. Env vars from LD is populated to store & local variable
 * 3. Env vars related services (Mix panel, Sentry etc) are inited
 * 4. App is mounted
 * 5. Services to be inited after app mount should fetch keys from store
 */

Vue.config.productionTip = false;
const nprogress = new NProgress({ parent: "body" });
const ldUser = { key: process.env.VUE_APP_WL_USER || "economize" };
let envVars = {};

const populateEnvVars = (ld) => {
  envVars = ld.variation("frontend-env", {});
  if (process.env.VUE_APP_WL_USER === "economize") {
    envVars = envVars[process.env.VUE_APP_MODE];
  }
  store.commit("user/SET_STATE", {
    keys: envVars,
  });
};

const initServices = () => {
  Vue.prototype.$auth0 = auth0;
  // Init mix panel
  const mixpanel = require("mixpanel-browser");
  mixpanel.init(envVars.VUE_APP_MIXPANEL_KEY);
  Vue.prototype.$mixpanel = mixpanel;
  // Init pusher
  // const appKey = envVars.VUE_APP_PUSHER_KEY;
  // const pusherOpts = {
  //   cluster: "ap2",
  //   forceTLS: true,
  // };
  // if (process.env.VUE_APP_MODE === "dev") {
  //   pusherOpts.forceTLS = false;
  // }
  // const pusher = new Pusher(appKey, pusherOpts);
  // Vue.prototype.$pusher = pusher;
};

const initApp = () => {
  new Vue({
    router,
    store,
    nprogress,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
};

let ldKey = process.env.VUE_APP_LAUNCHDARKLY_TOKEN;
if (process.env.VUE_APP_MODE === "dev") {
  ldKey = "60ace4532686e40e2ebe1426";
}
/* checking if LDClient is an object & but didn't have any property like version - assuming some error object is returned
 another case is it can be returned as null/undefined (network issues/server failures etc)
 in those cases - load the app in normal fashion w/o blocking */
if (
  (typeof LDClient === "object" &&
    LDClient !== null &&
    !Object.hasOwnProperty.call(LDClient, "version")) ||
  !LDClient
) {
  initApp();
} else {
  const ld = LDClient.initialize(ldKey, ldUser);
  // Invalid user check
  ld.waitForInitialization().catch(() => {
    store.commit("user/SET_STATE", {
      maintenanceMode: true,
    });
    initApp();
    initServices();
  });
  ld.waitUntilReady()
    .then(() => {
      ld.identify(ldUser)
        .then(() => {
          store.commit("user/SET_STATE", {
            features: ld,
          });
          const maintenanceMode = ld.variation("frontend-features", {
            maintenanceMode: true,
          }).maintenanceMode;
          store.commit("user/SET_STATE", {
            maintenanceMode: maintenanceMode,
          });
          // Mount frontend after initializing launch darkly
          initApp();
          populateEnvVars(ld);
          initServices();
        })
        .catch(() => {
          // Check if LD is down
          store.commit("user/SET_STATE", {
            maintenanceMode: true,
          });
          initApp();
          initServices();
        });
    })
    .catch((err) => {
      initApp();
    });
}
