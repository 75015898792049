<template>
  <div v-if="errorMessage" class="text-center link-dataset">
    <h4 style="font-weight: bold">
      <lottie
        :options="{
          animationData: animationData,
          loop: true,
          autoplay: true,
        }"
        :width="width"
        :height="height"
      />
      <br />
      {{ errorMessage }}
    </h4>
  </div>
</template>
<script>
import common from "@/views/dashboard/common";
import Lottie from "vue-lottie";

export default {
  name: "BaseErrorMessage",
  components: {
    Lottie,
  },
  data() {
    return {
      somethingWentWrongAnimationData: require("@/assets/somethingWentWrongAnimationData.json"),
      permissionDeniedAnimationData: require("@/assets/permissionDeniedAnimationData.json"),
      authenticationFailedAnimationData: require("@/assets/authenticationFailedAnimationData.json"),
      errorWarningAnimationData: require("@/assets/errorWarningAnimationData.json"),
      animationData: null,
      width: 60,
      height: 60,
    };
  },
  props: {
    errorCode: {
      type: String,
      default: "",
    },
    customErrorMessage: {
      type: String,
      default: "",
    },
  },
  inheritAttrs: true,
  computed: {
    errorMessage() {
      switch (this.errorCode) {
        case "PermissionDenied":
          this.animationData = this.permissionDeniedAnimationData;
          break;
        case "AuthenticationFailed":
        case "NoAuthenticationInformation":
          this.animationData = this.authenticationFailedAnimationData;
          break;
        case "InternalServerError":
        case "ResourceNotFound":
        case "InvalidRequestData":
        case "InvalidParameterValue":
        case "ResourceAlreadyExists":
        case "DataNotFound":
          this.animationData = this.somethingWentWrongAnimationData;
          break;
        default:
          this.animationData = this.errorWarningAnimationData;
          (this.width = 80), (this.height = 80);
          break;
      }
      if (this.customErrorMessage) {
        return this.customErrorMessage;
      }
      return this.getErrorMessage(this.errorCode);
    },
  },
  methods: {
    getErrorMessage(errorCode) {
      return common.errorMessageMap[errorCode];
    },
  },
};
</script>

<style>
.link-dataset {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
