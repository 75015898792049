var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"input-wrapper"},[(_vm.category === 'text')?_c('span',[_c('a-input',{class:'input ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        maxWidth: _vm.max,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"type":"text","placeholder":_vm.inputValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"html-type":{ HTMLType: _vm.HTMLType }},on:{"keyup":_vm.handleChange,"change":_vm.handleChange,"input":function($event){return _vm.$emit('input', $event.target.value)}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_vm._v(" "+_vm._s(_vm.inputText)+" ")])],1):_vm._e(),(_vm.category === 'text-date')?_c('span',[_c('a-input',_vm._g(_vm._b({class:'input ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        maxWidth: _vm.max,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"type":"text","placeholder":_vm.inputValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"html-type":{ HTMLType: _vm.HTMLType }},on:{"keyup":_vm.handleChange,"change":_vm.handleChange,"input":function($event){return _vm.$emit('input', $event.target.value)}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},'a-input',_vm.$attrs,false),_vm.$listeners),[_vm._v(" "+_vm._s(_vm.inputText)+" ")])],1):(_vm.category === 'password')?_c('span',[_c('a-input-password',{class:'  ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}})],1):(_vm.category === 'email')?_c('span',[_c('a-input',{class:'input email ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"type":"email","placeholder":_vm.inputValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}},[_vm._v(" "+_vm._s(_vm.customValue)+" ")])],1):(_vm.category === 'disabled')?_c('span',[_c('a-input',{class:'input disabled ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"type":"disabled","placeholder":_vm.inputValue,"disabled":true,"html-type":{ HTMLType: _vm.HTMLType }}})],1):(_vm.category === 'dropdown')?_c('span',{staticClass:"span"},[_c('a-select',{class:' dropdown ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"disabled":_vm.disableState,"id":_vm.idValue,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"change":_vm.handleChange},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},_vm._l((_vm.datalist),function(dataOption){return _c('a-select-option',{key:dataOption[_vm.index],attrs:{"value":dataOption[_vm.index]}},[_vm._v(" "+_vm._s(dataOption[_vm.index])+" ")])}),1)],1):(_vm.category === 'dropdown-template')?_c('span',{staticClass:"span"},[_c('a-select',{class:' dropdown ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"change":_vm.handleChange},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_vm._l((_vm.datalist),function(dataOption,index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v(" "+_vm._s(dataOption)+" ")])})],2)],1):(_vm.category === 'dropdown-template-with-value')?_c('span',{staticClass:"span"},[_c('a-select',{class:'dropdown ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"change":_vm.handleChange},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_vm._l((_vm.datalist),function(dataOption,index){return _c('a-select-option',{key:index,attrs:{"value":dataOption}},[_vm._v(" "+_vm._s(dataOption)+" ")])})],2)],1):(_vm.category === 'multiple-dropdown')?_c('span',{staticClass:"span"},[_c('a-select',{class:'dropdown ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"disabled":_vm.disableState,"default-value":_vm.defaultValue ? _vm.defaultValue : [],"id":_vm.idValue,"mode":_vm.modeSample,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"change":_vm.handleChange}},_vm._l((_vm.datalist),function(dataOption,index){return _c('a-select-option',{key:index,attrs:{"disabled":_vm.disableState,"value":dataOption}},[_vm._v(" "+_vm._s(dataOption)+" ")])}),1)],1):(_vm.category === 'checkbox')?_c('span',[_c('a-checkbox',{staticClass:"checkbox",style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"disabled":_vm.disableState,"id":_vm.idValue,"checked":_vm.value},on:{"change":_vm.handleChange},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_vm._v(" "+_vm._s(_vm.customValue)+" ")])],1):(_vm.category === 'radio')?_c('span',[_c('a-radio',{staticClass:"radio",style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"change":_vm.onChange,"input":function($event){return _vm.$emit('input', $event.target.value)}}},[_vm._v(" "+_vm._s(_vm.customValue)+" ")])],1):(_vm.category === 'number')?_c('span',[_c('a-input',{class:'input number' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"type":"number","placeholder":_vm.inputValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}},[_vm._v(" "+_vm._s(_vm.customValue)+" ")])],1):(_vm.category === 'toggle')?_c('span',[_c('a-switch',{staticClass:"toggle",style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"disabled":_vm.disableState,"id":_vm.idValue,"html-type":{ HTMLType: _vm.HTMLType }},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1):(_vm.category === 'toggle-with-icon')?_c('span',[_c('a-switch',{staticClass:"toggle",style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"defaultChecked":_vm.defaultChecked,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value},on:{"change":_vm.handleChange}},[_c('a-icon',{attrs:{"slot":"checkedChildren","type":_vm.firstIconType},slot:"checkedChildren"}),_c('a-icon',{attrs:{"slot":"unCheckedChildren","type":_vm.secondIconType},slot:"unCheckedChildren"})],1)],1):(_vm.category === 'file')?_c('span',[_c('a-input',{class:'input file ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"type":"file","placeholder":_vm.inputValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"change":_vm.onChange,"input":function($event){return _vm.$emit('input', $event.target.value)}}})],1):(_vm.category === 'datepicker')?_c('span',[_c('a-date-picker',{class:'input datepicker ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }}})],1):(_vm.category === 'monthpicker')?_c('span',{staticClass:"span"},[_c('a-select',{class:'monthpicker ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"default-value":_vm.defaultValue,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"change":_vm.handleChange},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_vm._l((_vm.datalist),function(dataOption,index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v(" "+_vm._s(dataOption.slice(0, 4) + " - " + dataOption.slice(4))+" ")])})],2)],1):(_vm.category === 'datepicker-interval')?_c('span',[_c('a-range-picker',{class:'input datepicker-interval ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        maxWidth: _vm.max,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"default-value":_vm.defaultValue,"format":"YYYY-MM","mode":_vm.mode,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"html-type":{ HTMLType: _vm.HTMLType }},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"panelChange":_vm.panelChange}})],1):(_vm.category === 'datepicker-interval-extra-footer')?_c('span',[_c('a-range-picker',{class:'input datepicker-interval ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        maxWidth: _vm.max,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"showTime":_vm.showTime,"defaultValue":_vm.defaultValue,"format":"YYYY-MM-DD HH:mm:ss","disabled":_vm.disableState,"disabledDate":_vm.disabledDate,"disabledTime":_vm.disabledRangeTime,"id":_vm.idValue,"html-type":{ HTMLType: _vm.HTMLType }},on:{"ok":_vm.handleChange}})],1):(_vm.category === 'textarea')?_c('span',[_c('a-textarea',{class:'input textarea ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"rows":_vm.row,"col":_vm.columns,"required":!_vm.selected,"auto-size":_vm.autoSize,"html-type":{ HTMLType: _vm.HTMLType }}})],1):(_vm.category === 'input-search')?_c('span',[_c('a-input-search',{class:' input-search ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"auto-size":_vm.autoSize,"html-type":{ HTMLType: _vm.HTMLType }},on:{"search":_vm.onSearch}})],1):(_vm.category === 'input-with-search')?_c('span',[_c('a-input-search',{class:'input input-with-search ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"enter-button":_vm.enterButton,"auto-size":_vm.autoSize,"disabled":_vm.disableState,"id":_vm.idValue,"value":_vm.value,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}})],1):(_vm.category === 'input-custom-button')?_c('span',{staticStyle:{"width":"100%"}},[_c('a-input',{class:'input-custom-button ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"placeholder":_vm.inputValue,"size":_vm.size,"disabled":_vm.disableState,"id":_vm.idValue,"required":!_vm.selected,"html-type":{ HTMLType: _vm.HTMLType }},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('base-button',{staticStyle:{"position":"absolute","right":"-12px"},attrs:{"slot":"suffix","size":"large","enter-button":"enterButton","category":"primary","disabled":_vm.disableState,"value":_vm.customValue},on:{"click":_vm.handleClick},slot:"suffix"})],1)],1):(_vm.category === 'menu')?_c('span',[_c('a-dropdown',{attrs:{"placement":_vm.placement}},[_c('a-button',{style:({
          minWidth: _vm.minWidth,
          color: 'var(--e-ui-gray-6)',
        })},[_vm._v(_vm._s(_vm.selected)),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.datalist),function(dataOption){return _c('a-menu-item',{key:dataOption[_vm.index]},[_c('a',{key:_vm.index,style:([
              dataOption[_vm.index] == _vm.selected
                ? // TODO: CHANGE COLOR
                  { background: 'none' }
                : { background: 'none' } ]),attrs:{"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.handleClick(dataOption)}}},[_c('a-icon',{staticStyle:{"margin-right":"10px","color":"black"},attrs:{"type":"deployment-unit"}}),_vm._v(" "+_vm._s(dataOption[_vm.index]))],1)])}),1)],1)],1):_vm._e(),(_vm.category === 'text-form')?_c('span',[_c('a-input',_vm._g(_vm._b({class:'input ' + _vm.size,style:({
        backgroundColor: _vm.color,
        color: _vm.textColor,
        padding: _vm.padding,
        width: _vm.widthSize,
        height: _vm.heightSize,
        maxWidth: _vm.max,
        marginTop: _vm.marginTop,
        marginLeft: _vm.marginLeft,
        fontSize: _vm.fontSize,
        fontWeight: _vm.fontWeight,
      }),attrs:{"type":"text","placeholder":_vm.inputValue,"value":_vm.value}},'a-input',_vm.$attrs,false),_vm.$listeners),[_vm._v(" "+_vm._s(_vm.inputText)+" ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }