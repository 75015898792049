var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-text-wrapper",style:([_vm.textAlign && { textAlign: _vm.textAlign }])},[_c('span',{style:([
      _vm.theme.typography[_vm.type]
        ? _vm.theme.typography[_vm.type]
        : _vm.theme.typography['p-l'],
      _vm.theme.elevation[_vm.elevation] && { boxShadow: _vm.theme.elevation[_vm.elevation] },
      _vm.fontColor && { color: _vm.fontColor },
      _vm.textDecoration && { textDecoration: _vm.textDecoration },
      _vm.theme.color[_vm.color] && {
        color: _vm.theme.color[_vm.color],
      },
      _vm.whiteSpace && { whiteSpace: _vm.whiteSpace } ])},[_vm._v(" "+_vm._s(_vm.value)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }