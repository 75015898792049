<template>
  <div
    :style="[
      color && {
        color: theme.color[color] ? theme.color[color] : color,
      },
      bg && {
        backgroundColor: theme.color[bg] ? theme.color[bg] : bg,
      },
      theme.elevation[elevation] && { boxShadow: theme.elevation[elevation] },
      cursor && { cursor: cursor },
      overflow && { overflow: overflow },
      textAlign && { textAlign: textAlign },
      border && { border: `${border}px` },
      w && { width: typeof w === 'number' ? `${w}rem` : w },
      h && { height: typeof h === 'number' ? `${h}rem` : h },
      textDecoration && { textDecoration: textDecoration },
      m && { margin: typeof m === 'number' ? `${m}rem` : m },
      p && { padding: typeof p === 'number' ? `${p}rem` : p },
      mt && { marginTop: typeof mt === 'number' ? `${mt}rem` : mt },
      mb && { marginBottom: typeof mb === 'number' ? `${mb}rem` : mb },
      ml && { marginLeft: typeof ml === 'number' ? `${ml}rem` : ml },
      mr && { marginRight: typeof mr === 'number' ? `${mr}rem` : mr },
      pt && { paddingTop: typeof pt === 'number' ? `${pt}rem` : pt },
      pb && { paddingBottom: typeof pb === 'number' ? `${pb}rem` : pb },
      pl && { paddingLeft: typeof pl === 'number' ? `${pl}rem` : pl },
      pr && { paddingRight: typeof pr === 'number' ? `${pr}rem` : pr },
      borderRadius && {
        borderRadius: `${borderRadius}rem`,
      },
      borderBottom && {
        borderBottom: borderBottom,
      },
      borderTop && {
        borderTop: borderTop,
      },
      fontSize && { fontSize: `${fontSize}rem` },
      maxW && { maxWidth: typeof maxW === 'number' ? `${maxW}rem` : maxW },
      maxH && { maxHeight: typeof maxH === 'number' ? `${maxH}rem` : maxH },
      minW && { minWidth: typeof minW === 'number' ? `${minW}rem` : minW },
      minH && { minHeight: typeof minH === 'number' ? `${minH}rem` : minH },
      opacity && { opacity: opacity },
      whiteSpace && { whiteSpace: whiteSpace },
    ]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </div>
</template>
<script>
import theme from "@/components/theme";
export default {
  data() {
    return {
      theme,
    };
  },
  props: {
    /**
     * Color
     */
    color: {
      type: String,
      default: null,
    },
    elevation: {
      type: String,
      default: null,
    },
    cursor: {
      type: String,
      default: null,
    },
    /**
     * Background color number
     */
    bg: {
      type: String,
      default: null,
    },
    /**
     * overflow
     */
    overflow: {
      type: String,
      default: null,
    },
    /**
     * textAlign
     */
    textAlign: {
      type: String,
      default: null,
    },
    borderBottom: {
      type: String,
      default: null,
    },
    borderTop: {
      type: String,
      default: null,
    },
    /**
     * textDecoration
     */
    textDecoration: {
      type: String,
      default: null,
    },
    /**
     * margin
     */
    m: {
      type: [Number, String],
      default: null,
    },
    /**
     * margin top
     */
    mt: {
      type: [Number, String],
      default: null,
    },
    /**
     * margin bottom
     */
    mb: {
      type: [Number, String],
      default: null,
    },
    /**
     * margin left
     */
    ml: {
      type: [Number, String],
      default: null,
    },
    /**
     * margin right
     */
    mr: {
      type: [Number, String],
      default: null,
    },
    /**
     * padding number
     */
    p: {
      type: [Number, String],
      default: null,
    },
    /**
     * padding top
     */
    pt: {
      type: [Number, String],
      default: null,
    },
    /**
     * padding bottom
     */
    pb: {
      type: [Number, String],
      default: null,
    },
    /**
     * padding left
     */
    pl: {
      type: [Number, String],
      default: null,
    },
    /**
     * padding right
     */
    pr: {
      type: [Number, String],
      default: null,
    },

    /**
     * padding number
     */
    w: {
      type: [Number, String],
      default: null,
    },
    /**

    /**
     * padding number
     */
    h: {
      type: [Number, String],
      default: null,
    },
    /**
     * fontSize number
     */
    fontSize: {
      type: Number,
      default: null,
    },
    /**
     * borderRadius number
     */
    borderRadius: {
      type: Number,
      default: null,
    },
    /**
     * borderRadius number
     */
    maxW: {
      type: [Number, String],
      default: null,
    },
    /**
     * borderRadius number
     */
    maxH: {
      type: Number,
      default: null,
    },
    /**
     * borderRadius number
     */
    minW: {
      type: Number,
      default: null,
    },
    /**
     * borderRadius number
     */
    minH: {
      type: Number,
      default: null,
    },
    /**
     * borderRadius number
     */
    whiteSpace: {
      type: String,
      default: null,
    },
    /**
     * borderRadius number
     */
    border: {
      type: Number,
      default: null,
    },
    /**
     * opacity number
     */
    opacity: {
      type: Number,
      default: null,
    },
  },
  name: "BaseBox",
  inheritAttrs: false, // optional
};
</script>
