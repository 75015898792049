const theme = {
  elevation: {
    "elevation-1": `var(--e-ui-elevation-1)`,
    "elevation-2": `var(--e-ui-elevation-2)`,
    "elevation-3": `var(--e-ui-elevation-3)`,
  },
  color: {
    // BLUE
    "blue-7": `var(--e-ui-blue-7)`,
    "blue-6": `var(--e-ui-blue-6)`,
    "blue-5": `var(--e-ui-blue-5)`,
    "blue-4": `var(--e-ui-blue-4)`,
    "blue-3": `var(--e-ui-blue-3)`,
    "blue-2": `var(--e-ui-blue-2)`,
    "blue-1": `var(--e-ui-blue-1)`,
    // Purple
    "purple-7": `var(--e-ui-purple-7)`,
    "purple-6": `var(--e-ui-purple-6)`,
    "purple-5": `var(--e-ui-purple-5)`,
    "purple-4": `var(--e-ui-purple-4)`,
    "purple-3": `var(--e-ui-purple-3)`,
    "purple-2": `var(--e-ui-purple-2)`,
    "purple-1": `var(--e-ui-purple-1)`,
    // GRAY
    "gray-9": `var(--e-ui-gray-9)`,
    "gray-8": `var(--e-ui-gray-8)`,
    "gray-7": `var(--e-ui-gray-7)`,
    "gray-6": `var(--e-ui-gray-6)`,
    "gray-5": `var(--e-ui-gray-5)`,
    "gray-4": `var(--e-ui-gray-4)`,
    "gray-3": `var(--e-ui-gray-3)`,
    "gray-2": `var(--e-ui-gray-2)`,
    "gray-1": `var(--e-ui-gray-1)`,
    "gray-0.5": `var(--e-ui-gray-0-5)`,
    // RED
    "red-7": `var(--e-ui-red-7)`,
    "red-6": `var(--e-ui-red-6)`,
    "red-5": `var(--e-ui-red-5)`,
    "red-4": `var(--e-ui-red-4)`,
    "red-3": `var(--e-ui-red-3)`,
    "red-2": `var(--e-ui-red-2)`,
    "red-1": `var(--e-ui-red-1)`,
    // yellow
    "yellow-7": `var(--e-ui-yellow-7)`,
    "yellow-6": `var(--e-ui-yellow-6)`,
    "yellow-5": `var(--e-ui-yellow-5)`,
    "yellow-4": `var(--e-ui-yellow-4)`,
    "yellow-3": `var(--e-ui-yellow-3)`,
    "yellow-2": `var(--e-ui-yellow-2)`,
    "yellow-1": `var(--e-ui-yellow-1)`,
    // green
    "green-7": `var(--e-ui-green-7)`,
    "green-6": `var(--e-ui-green-6)`,
    "green-5": `var(--e-ui-green-5)`,
    "green-4": `var(--e-ui-green-4)`,
    "green-3": `var(--e-ui-green-3)`,
    "green-2": `var(--e-ui-green-2)`,
    "green-1": `var(--e-ui-green-1)`,
  },
  typography: {
    "card-title": {
      fontSize: `var(--e-ui-fs-6)`,
      lineHeight: `var(--e-ui-lh-6)`,
      fontWeight: 700,
    },
    "card-description": {
      fontSize: `var(--e-ui-fs-5)`,
      lineHeight: `var(--e-ui-lh-5)`,
      fontWeight: 700,
    },
    h2: {
      fontSize: `var(--e-ui-fs-9)`,
      lineHeight: `var(--e-ui-lh-9)`,
      fontWeight: 700,
    },
    h1: {
      fontSize: `var(--e-ui-fs-10)`,
      lineHeight: `var(--e-ui-lh-10)`,
      fontWeight: 700,
    },
    h2: {
      fontSize: `var(--e-ui-fs-9)`,
      lineHeight: `var(--e-ui-lh-9)`,
      fontWeight: 700,
    },
    h3: {
      fontSize: `var(--e-ui-fs-8)`,
      lineHeight: `var(--e-ui-lh-8)`,
      fontWeight: 700,
    },
    h4: {
      fontSize: `var(--e-ui-fs-7)`,
      lineHeight: `var(--e-ui-lh-7)`,
      fontWeight: 700,
    },
    h5: {
      fontSize: `var(--e-ui-fs-6)`,
      lineHeight: `var(--e-ui-lh-6)`,
      fontWeight: 700,
    },
    h6: {
      fontSize: `var(--e-ui-fs-5)`,
      lineHeight: `var(--e-ui-lh-5)`,
      fontWeight: 700,
    },
    "h1-6": {
      fontSize: `var(--e-ui-fs-10)`,
      lineHeight: `var(--e-ui-lh-10)`,
      fontWeight: 600,
    },
    "h2-6": {
      fontSize: `var(--e-ui-fs-9)`,
      lineHeight: `var(--e-ui-lh-9)`,
      fontWeight: 600,
    },
    "h3-6": {
      fontSize: `var(--e-ui-fs-8)`,
      lineHeight: `var(--e-ui-lh-8)`,
      fontWeight: 600,
    },
    "h4-6": {
      fontSize: `var(--e-ui-fs-7)`,
      lineHeight: `var(--e-ui-lh-7)`,
      fontWeight: 800,
    },
    "h5-6": {
      fontSize: `var(--e-ui-fs-6)`,
      lineHeight: `var(--e-ui-lh-6)`,
      fontWeight: 600,
    },
    "h6-6": {
      fontSize: `var(--e-ui-fs-5)`,
      lineHeight: `var(--e-ui-lh-5)`,
      fontWeight: 600,
    },
    "body-xl": {
      fontSize: `var(--e-ui-fs-5)`,
      lineHeight: `var(--e-ui-lh-5)`,
      fontWeight: 600,
    },
    "body-l": {
      fontSize: `var(--e-ui-fs-4)`,
      lineHeight: `var(--e-ui-lh-4)`,
      fontWeight: 600,
    },
    "body-m": {
      fontSize: `var(--e-ui-fs-3)`,
      lineHeight: `var(--e-ui-lh-3)`,
      fontWeight: 600,
    },
    "body-sm": {
      fontSize: `var(--e-ui-fs-2)`,
      lineHeight: `var(--e-ui-lh-2)`,
      fontWeight: 600,
    },
    "body-x-sm": {
      fontSize: `var(--e-ui-fs-1)`,
      lineHeight: `var(--e-ui-lh-1)`,
      fontWeight: 600,
    },
    "body-xl-5": {
      fontSize: `var(--e-ui-fs-5)`,
      lineHeight: `var(--e-ui-lh-5)`,
      fontWeight: 500,
    },
    "body-l-5": {
      fontSize: `var(--e-ui-fs-4)`,
      lineHeight: `var(--e-ui-lh-4)`,
      fontWeight: 500,
    },
    "body-m-5": {
      fontSize: `var(--e-ui-fs-3)`,
      lineHeight: `var(--e-ui-lh-3)`,
      fontWeight: 500,
    },
    "body-sm-5": {
      fontSize: `var(--e-ui-fs-2)`,
      lineHeight: `var(--e-ui-lh-2)`,
      fontWeight: 500,
    },
    "body-x-sm-5": {
      fontSize: `var(--e-ui-fs-1)`,
      lineHeight: `var(--e-ui-lh-1)`,
      fontWeight: 500,
    },
    "body-xl-4": {
      fontSize: `var(--e-ui-fs-5)`,
      lineHeight: `var(--e-ui-lh-5)`,
      fontWeight: 400,
    },
    "body-l-4": {
      fontSize: `var(--e-ui-fs-4)`,
      lineHeight: `var(--e-ui-lh-4)`,
      fontWeight: 400,
    },
    "body-m-4": {
      fontSize: `var(--e-ui-fs-3)`,
      lineHeight: `var(--e-ui-lh-3)`,
      fontWeight: 400,
    },
    "body-sm-4": {
      fontSize: `var(--e-ui-fs-2)`,
      lineHeight: `var(--e-ui-lh-2)`,
      fontWeight: 400,
    },
    "body-x-sm-4": {
      fontSize: `var(--e-ui-fs-1)`,
      lineHeight: `var(--e-ui-lh-1)`,
      fontWeight: 400,
    },
    "p-l": {
      fontSize: `var(--e-ui-fs-pl)`,
      lineHeight: `var(--e-ui-lh-pl)`,
      fontWeight: 500,
    },
    "p-m": {
      fontSize: `var(--e-ui-fs-pm)`,
      lineHeight: `var(--e-ui-lh-pm)`,
      fontWeight: 500,
    },
    "p-sm": {
      fontSize: `var(--e-ui-fs-ps)`,
      lineHeight: `var(--e-ui-lh-ps)`,
      fontWeight: 500,
    },
  },
};

export default theme;
