import store from "@/store";
import { frontendFeatures } from "../../WtConstants";

const gcpMenuData = [
  {
    title: "Home",
    key: "home",
    icon: "fas fa-home",
    url: "/dashboard/gcp/",
  },
  {
    title: "Explorer",
    key: "assets",
    icon: "fa-solid fa-compass",
    url: "/dashboard/gcp/explorer",
  },
  {
    title: "Reports",
    key: "cost_reports",
    icon: "fas fa-chart-line",
    url: "/dashboard/gcp/reports",
  },
  {
    title: "Incidents",
    key: "alerts",
    icon: "fas fa-donate",
    url: "/dashboard/gcp/incidents",
  },
  {
    title: "Recommendations",
    key: "recommendations",
    icon: "fas fa-question-circle",
    url: "/dashboard/gcp/recommendations",
    total: 0,
  },
  // {
  //   title: "Labels",
  //   key: "labels",
  //   icon: "fas fa-tag",
  //   url: "/dashboard/gcp/labels",
  // },
];

const awsMenuData = [
  {
    title: "Home",
    key: "home",
    icon: "fas fa-home",
    url: "/dashboard/aws/home",
  },
  {
    title: "Reports",
    key: "cost_reports",
    icon: "fas fa-chart-line",
    url: "/dashboard/aws/home/reports",
  },
  {
    title: "Incidents",
    key: "alerts",
    icon: "fas fa-donate",
    url: "/dashboard/aws/home/incidents",
  },
  {
    title: "Recommendations",
    key: "recommendations",
    icon: "fas fa-question-circle",
    url: "/dashboard/aws/home/recommendations",
    total: 0,
  },
];

// filter out disallowed menu items
const getFilteredMenu = function (gcpMenuData, tabConfig) {
  const frontendConfigs = store.state.user.features.variation(
    "frontend-features",
    frontendFeatures
  );
  const disallowedMenuItems = frontendConfigs.disallowedMenuItems;

  if (!tabConfig.disabled_tabs) {
    tabConfig.disabled_tabs = [];
  }
  if (!tabConfig.disabled_subtabs) {
    tabConfig.disabled_subtabs = [];
  }
  // Filter parent tabs by feature flag (for WL) & firebase (for users), filter sub tabs by firebase
  return gcpMenuData
    .filter(
      (item) =>
        !disallowedMenuItems.includes(item.key) &&
        !tabConfig.disabled_tabs.includes(item.key)
    )
    .map((item) => {
      if (!("children" in item)) return item;
      item.children = item.children.filter(
        (childItem) => !tabConfig.disabled_subtabs.includes(childItem.key)
      );
      return item;
    });
};

const getMenu = function () {
  const tabConfig = store.state.user.tabConfig;
  if (store.state.user.maintenanceMode) {
    return [];
  }
  let type = store.state.user.selectedCloudProvider;
  if (!type) type = "GCP"; // return GCP menu when type is unavailable
  if (type === "GCP") {
    return getFilteredMenu(gcpMenuData, tabConfig);
  } else if (type === "AWS") {
    return awsMenuData;
  } else {
    return [];
  }
};

export { getMenu };
