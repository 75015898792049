<template>
  <span class="input-wrapper">
    <!-- Input Properties: text -->
    <span v-if="category === 'text'">
      <a-input
        :class="'input ' + size"
        type="text"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          maxWidth: max,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        v-model="showDialog"
        @keyup="handleChange"
        @change="handleChange"
        @input="$emit('input', $event.target.value)"
        :html-type="{ HTMLType }"
      >
        {{ inputText }}
      </a-input>
    </span>
    <span v-if="category === 'text-date'">
      <a-input
        :class="'input ' + size"
        type="text"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          maxWidth: max,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        v-model="showDialog"
        @keyup="handleChange"
        @change="handleChange"
        @input="$emit('input', $event.target.value)"
        :html-type="{ HTMLType }"
        v-bind="$attrs"
        v-on="$listeners"
      >
        {{ inputText }}
      </a-input>
    </span>

    <!-- Input Properties: password -->
    <span v-else-if="category === 'password'">
      <a-input-password
        :class="'  ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :required="!selected"
      >
      </a-input-password>
    </span>

    <!-- Input Properties: email -->
    <span v-else-if="category === 'email'">
      <a-input
        :class="'input email ' + size"
        type="email"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :required="!selected"
        :html-type="{ HTMLType }"
      >
        {{ customValue }}
      </a-input>
    </span>

    <!-- Input Properties: disabled -->
    <span v-else-if="category === 'disabled'">
      <a-input
        :class="'input disabled ' + size"
        type="disabled"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="true"
        :html-type="{ HTMLType }"
      >
      </a-input>
    </span>

    <!-- Input Properties: dropdown -->
    <span class="span" v-else-if="category === 'dropdown'">
      <a-select
        :class="' dropdown ' + size"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        :required="!selected"
        :html-type="{ HTMLType }"
        v-model="showDialog"
        @change="handleChange"
      >
        <!-- :value="value" -->

        <a-select-option
          v-for="dataOption in datalist"
          v-bind:key="dataOption[index]"
          :value="dataOption[index]"
        >
          {{ dataOption[index] }}
        </a-select-option>
      </a-select>
    </span>

    <!-- Input Properties: dropdown -->
    <span class="span" v-else-if="category === 'dropdown-template'">
      <a-select
        :class="' dropdown ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        :required="!selected"
        :html-type="{ HTMLType }"
        v-model="showDialog"
        @change="handleChange"
      >
        <template>
          <a-select-option
            v-for="(dataOption, index) in datalist"
            v-bind:key="index"
            :value="index"
          >
            {{ dataOption }}
          </a-select-option>
        </template>
      </a-select>
    </span>
    <!-- Input Properties: dropdown -->
    <span class="span" v-else-if="category === 'dropdown-template-with-value'">
      <a-select
        :class="'dropdown ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        :required="!selected"
        :html-type="{ HTMLType }"
        v-model="showDialog"
        @change="handleChange"
      >
        <template>
          <a-select-option
            v-for="(dataOption, index) in datalist"
            v-bind:key="index"
            :value="dataOption"
          >
            {{ dataOption }}
          </a-select-option>
        </template>
      </a-select>
    </span>

    <span class="span" v-else-if="category === 'multiple-dropdown'">
      <a-select
        :class="'dropdown ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :default-value="defaultValue ? defaultValue : []"
        :id="idValue"
        :mode="modeSample"
        :required="!selected"
        :html-type="{ HTMLType }"
        @change="handleChange"
      >
        <a-select-option
          :disabled="disableState"
          v-for="(dataOption, index) in datalist"
          v-bind:key="index"
          :value="dataOption"
        >
          {{ dataOption }}
        </a-select-option>
      </a-select>
    </span>

    <!-- Input Properties: checkbox -->
    <span v-else-if="category === 'checkbox'">
      <a-checkbox
        class="checkbox"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        v-model="showDialog"
        @change="handleChange"
        :checked="value"
      >
        {{ customValue }}
      </a-checkbox>
    </span>

    <!-- Input Properties: radio -->
    <span v-else-if="category === 'radio'">
      <a-radio
        class="radio"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        @change="onChange"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :required="!selected"
        :html-type="{ HTMLType }"
      >
        {{ customValue }}
      </a-radio>
    </span>

    <!-- Input Properties: number -->
    <span v-else-if="category === 'number'">
      <a-input
        type="number"
        :class="'input number' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :required="!selected"
        :html-type="{ HTMLType }"
      >
        {{ customValue }}
      </a-input>
    </span>

    <!-- Input Properties: toggle -->
    <span v-else-if="category === 'toggle'">
      <a-switch
        class="toggle"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        v-model="showDialog"
        :id="idValue"
        :html-type="{ HTMLType }"
      />
    </span>

    <!-- Input Properties: toggle-with-icon -->
    <span v-else-if="category === 'toggle-with-icon'">
      <a-switch
        class="toggle"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :defaultChecked="defaultChecked"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        @change="handleChange"
      >
        <a-icon slot="checkedChildren" :type="firstIconType" />
        <a-icon slot="unCheckedChildren" :type="secondIconType" />
      </a-switch>
    </span>

    <!-- Input Properties: file -->
    <span v-else-if="category === 'file'">
      <a-input
        :class="'input file ' + size"
        type="file"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        @change="onChange"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :required="!selected"
        :html-type="{ HTMLType }"
      >
      </a-input>
    </span>

    <!-- Input Properties: date -->
    <span v-else-if="category === 'datepicker'">
      <a-date-picker
        :class="'input datepicker ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :required="!selected"
        :html-type="{ HTMLType }"
      >
      </a-date-picker>
    </span>

    <!-- Input Properties: date -->
    <span class="span" v-else-if="category === 'monthpicker'">
      <a-select
        :class="'monthpicker ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :default-value="defaultValue"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        :required="!selected"
        :html-type="{ HTMLType }"
        v-model="showDialog"
        @change="handleChange"
      >
        <template>
          <a-select-option
            v-for="(dataOption, index) in datalist"
            v-bind:key="index"
            :value="index"
          >
            {{ dataOption.slice(0, 4) + " - " + dataOption.slice(4) }}
          </a-select-option>
        </template>
      </a-select>
    </span>

    <!-- Input Properties: datepicker-interval -->
    <span v-else-if="category === 'datepicker-interval'">
      <a-range-picker
        :class="'input datepicker-interval ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          maxWidth: max,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :default-value="defaultValue"
        format="YYYY-MM"
        :mode="mode"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @panelChange="panelChange"
        :html-type="{ HTMLType }"
      />
    </span>

    <!-- Input Properties: datepicker-interval-extra-footer -->
    <span v-else-if="category === 'datepicker-interval-extra-footer'">
      <a-range-picker
        :class="'input datepicker-interval ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          maxWidth: max,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :showTime="showTime"
        :defaultValue="defaultValue"
        format="YYYY-MM-DD HH:mm:ss"
        :disabled="disableState"
        :disabledDate="disabledDate"
        :disabledTime="disabledRangeTime"
        :id="idValue"
        @ok="handleChange"
        :html-type="{ HTMLType }"
      />
    </span>

    <!-- Input Properties: textarea -->
    <span v-else-if="category === 'textarea'">
      <a-textarea
        :class="'input textarea ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :rows="row"
        :col="columns"
        :required="!selected"
        :auto-size="autoSize"
        :html-type="{ HTMLType }"
      >
      </a-textarea>
    </span>

    <!-- Input Properties: input-search -->
    <span v-else-if="category === 'input-search'">
      <a-input-search
        :class="' input-search ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        @search="onSearch"
        :auto-size="autoSize"
        :html-type="{ HTMLType }"
      >
      </a-input-search>
    </span>

    <!-- Input Properties: input-with-search -->
    <span v-else-if="category === 'input-with-search'">
      <a-input-search
        :class="'input input-with-search ' + size"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :enter-button="enterButton"
        :auto-size="autoSize"
        :disabled="disableState"
        :id="idValue"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :required="!selected"
        :html-type="{ HTMLType }"
      >
      </a-input-search>
    </span>

    <!-- Input Properties: input-custom-button -->
    <span style="width: 100%" v-else-if="category === 'input-custom-button'">
      <a-input
        :class="'input-custom-button ' + size"
        :placeholder="inputValue"
        :size="size"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :disabled="disableState"
        :id="idValue"
        v-model="showDialog"
        :required="!selected"
        :html-type="{ HTMLType }"
      >
        <base-button
          style="position: absolute; right: -12px"
          size="large"
          slot="suffix"
          enter-button="enterButton"
          category="primary"
          @click="handleClick"
          :disabled="disableState"
          :value="customValue"
        />
      </a-input>
    </span>
    <!-- Input Properties: input-custom-button -->
    <span v-else-if="category === 'menu'">
      <a-dropdown :placement="placement">
        <a-button
          :style="{
            minWidth: minWidth,
            color: 'var(--e-ui-gray-6)',
          }"
          >{{ selected }}<a-icon type="down"
        /></a-button>
        <a-menu slot="overlay">
          <a-menu-item
            v-for="dataOption in datalist"
            v-bind:key="dataOption[index]"
          >
            <a
              :style="[
                dataOption[index] == selected
                  ? // TODO: CHANGE COLOR
                    { background: 'none' }
                  : { background: 'none' },
              ]"
              target="_blank"
              :key="index"
              @click="handleClick(dataOption)"
              rel="noopener noreferrer"
            >
              <a-icon
                type="deployment-unit"
                style="margin-right: 10px; color: black"
              />
              {{ dataOption[index] }}</a
            >
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </span>

    <span v-if="category === 'text-form'">
      <a-input
        :class="'input ' + size"
        type="text"
        :placeholder="inputValue"
        :style="{
          backgroundColor: color,
          color: textColor,
          padding: padding,
          width: widthSize,
          height: heightSize,
          maxWidth: max,
          marginTop: marginTop,
          marginLeft: marginLeft,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }"
        :value="value"
        v-bind="$attrs"
        v-on="$listeners"
      >
        {{ inputText }}
      </a-input>
    </span>
  </span>
</template>
<script>
import {
  Input,
  Icon,
  Button,
  Select,
  Checkbox,
  Radio,
  Switch,
  DatePicker,
} from "ant-design-vue";

export default {
  name: "BaseInput",
  components: {
    "a-input": Input,
    "a-icon": Icon,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-checkbox": Checkbox,
    "a-radio": Radio,
    "a-switch": Switch,
    "a-input-password": Input.Password,
    "a-date-picker": DatePicker,
    "a-range-picker": DatePicker.RangePicker,
    "a-textarea": Input.TextArea,
    "a-input-search": Input.Search,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    /**
     * `text` | `password` | `email` | `disabled` | `dropdown` | `dropdown-template` | `multiple-dropdown` | `checkbox` | `radio` | `number` | `toggle` | `toggle-with-icon` | `file` | `datepicker` | `monthpicker` | `datepicker-interval` | `datepicker-interval-extra-footer` | `textarea` | `input-search`  | `input-with-search` | `input-custom-button`
     */
    category: {
      type: String,
      default: "text",
    },
    placement: { type: String, default: "bottomCenter" },
    minWidth: String,
    selected: String,
    size: [Number, String],
    /**
     * Any color of background button
     */
    color: String,
    /**
     * Any color of Text button
     */
    textColor: String,
    /**
     * Placeholder of limit categories
     */
    inputValue: [String, Array],
    HTMLType: String,
    /**
     * style of width
     */
    widthSize: String,
    /**
     * style of height
     */
    heightSize: String,
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * style of font Size
     */
    fontSize: Number,
    /**
     * style of font Size
     */
    fontWeight: String,
    value: [String, Boolean, Array, Object, Number],
    max: Number,
    index: String,
    dateFormat: String,
    customValue: String,
    disableState: Boolean,
    idValue: [String, Boolean, Array, Object],
    inputText: [String, Array],
    datalist: Array,
    vFor: String,
    marginTop: [Number, String],
    marginLeft: [Number, String],
    innerValue: String,
    dataOption: String,
    checked: {
      type: Boolean,
      default: false,
    },
    defaultChecked: {
      type: Boolean,
      default: false,
    },
    panelChange: String,
    mode: String,
    dateFormat: String,
    defaultValue: [String, Boolean, Array, Object, Number],
    padding: Number,
    columns: String,
    row: String,
    slotValue: String,
    enterButton: String,
    autoSize: String,
    onChange: {
      type: Function,
      default: () => {},
    },
    optionType: String,
    innerValueFirst: String,
    innerValueSecond: String,
    customValueFirst: String,
    customValueSecond: String,
    disabledDate: String,
    disabledRangeTime: String,
    showTime: [String, Boolean, Array, Object],
    firstIconType: String,
    secondIconType: String,
    modeSample: String,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(
          "input",
          typeof this.value === "boolean" ? !this.value : value
        );
      },
    },
  },
  inheritAttrs: true,
  methods: {
    handleClick(value) {
      this.$emit("on-click", value);
      // this.showAdvanced = !this.showAdvanced;
    },
    handleChange(value) {
      this.$emit("onChange", value);
      this.$emit("onOk", value);
      this.$emit("onKeyup", value);
      this.$emit("onSearch", value);
    },
    openSelect() {
      this.open = true;
    },
    closeSelect() {
      setTimeout(() => {
        this.open = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
/* CSS properties for all small width Inputs */
.small {
  width: 40% !important;
}

/* CSS properties for all medium width Inputs */
.medium {
  width: 70% !important;
}

/* CSS properties for all large width Inputs */
.large {
  width: 100% !important;
}

/* CSS properties for all Inputs */
.input,
.ant-input {
  height: 42px !important;
}
.input,
.ant-select-selection--multiple,
.ant-select-selection--single,
.ant-input,
.ant-input-lg {
  border: 0.25px solid var(--e-ui-gray-2) !important;
  box-shadow: var(--e-ui-elevation-1) !important;
  border-radius: 6px !important;
  color: var(--e-ui-gray-5) !important;

  &::placeholder {
    color: var(--e-ui-gray-5) !important;
  }

  &:placeholder-shown {
    color: var(--e-ui-gray-5) !important;
  }

  &:focus {
    border: 0.4px solid var(--e-ui-blue-2) !important;
    box-shadow: var(--e-ui-drop-shadow-hover) !important;
    transition: all 0.3s ease !important;
  }
  &:hover {
    border: 0.4px solid var(--e-ui-blue-2) !important;
    box-shadow: var(--e-ui-drop-shadow-hover) !important;
    transition: all 0.3s ease !important;
  }

  &:disabled {
    background: var(--e-ui-gray-2) !important;
    &:focus {
      border: none;
      box-shadow: var(--e-ui-elevation-1) !important;
      transition: all 0.3s ease !important;
    }
    &:hover {
      border: none;
      box-shadow: var(--e-ui-elevation-1) !important;
      transition: all 0.3s ease !important;
    }
  }
}
.ant-select-selection,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-lg,
.input-custom-button {
  border: none;
}

.ant-select-selection__placeholder {
  color: var(--e-ui-gray-5) !important;
}
.ant-form-item-label > * {
  color: var(--e-ui-gray-7) !important;
}
/* CSS properties for Disabled Input */
.disabled {
  background: var(--e-ui-gray-2) !important;
  &:focus {
    border: none;
    box-shadow: var(--e-ui-elevation-1) !important;
    transition: all 0.3s ease !important;
  }
  &:hover {
    border: none;
    box-shadow: var(--e-ui-elevation-1) !important;
    transition: all 0.3s ease !important;
  }
}

// HOVER

.ant-select-dropdown-menu-item {
  margin-left: 2px !important;
  &:hover {
    margin-left: 0px !important;
    background-color: var(--e-ui-blue-1) !important;
    border-left: 2px solid var(--e-ui-blue-5) !important;
    color: var(--e-ui-blue-5) !important;
  }
}
.ant-select-dropdown-menu-item-selected {
  background-color: var(--e-ui-blue-1) !important;
  border-left: 2px solid var(--e-ui-blue-5) !important;
  margin-left: 0px !important;
  font-weight: normal !important;
  color: var(--e-ui-blue-5) !important;
}
</style>
